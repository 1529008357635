import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaXTwitter } from 'react-icons/fa6';

const ComingSoonModal = ({ isOpen, onClose }) => {
  const modalVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.8,
      y: 50
    },
    visible: { 
      opacity: 1, 
      scale: 1,
      y: 0,
      transition: { 
        type: '',
        damping: 25,
        stiffness: 500
      }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      y: 50,
      transition: { 
        duration: 0.1
      }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <motion.div
            className="bg-[#0B0B20] p-44 rounded-lg border border-white/10 w-1/2 h-1/2 max-w-2xl max-h-96 flex flex-col justify-center items-center text-center"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-5xl font-light mb-6 text-white">Coming Soon!</h2>
            <p className="text-xl text-white/80 mb-8">
              This feature is not available yet, but be on the lookout for updates.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <button
                className="px-6 py-3 bg-white text-black text-lg font-medium rounded-lg hover:bg-gray-200 transition duration-300"
                onClick={onClose}
              >
                Okay 😔
              </button>
              <a
                href="https://x.com/QuasmIO"
                target="_blank"
                rel="noopener noreferrer"
                className="px-6 py-3 bg-black border border-white/80 text-white text-lg font-medium rounded-lg hover:bg-gray-800 transition duration-300 flex items-center justify-center"
              >
                <FaXTwitter className="mr-2" />
                Follow us
              </a>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ComingSoonModal;