import {BaseService} from "../core/base";

class CountryService extends BaseService{
    
    async list(){
        const resp = await this.apiClient.get("/billing/countries")
        return resp.data;
    }
}

const countryService = new CountryService();
export default countryService;
