import React, { useState } from 'react';
import { IoMdCopy } from "react-icons/io";
import { toast } from "react-toastify";

export const DetailAppchainCard = ({ appchain }) => {
  const {
    name,
    full_url,
    url,
    deployment_status,
    deployment_time,
    status,
    metadata,
  } = appchain;

  const [metadataVisible, setMetadataVisible] = useState(false);

  const Badge = ({ children, className }) => (
    <span className={`text-xs font-medium px-2 py-1 rounded-full ${className}`}>
      {children}
    </span>
  );

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(full_url);
    toast.success("URL copied");
  };

  const getCustomStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'running':
        return 'bg-[#233024] text-[#C8FFAE]';
      case 'stopped':
        return 'bg-[#232730] text-[#AECAFF]';
      case 'error':
        return 'bg-[#301E1E] text-[#FF9A9A]';
      default:
        return 'bg-[#232730] text-[#AECAFF]';
    }
  };

  const getCustomDeploymentStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'deployed':
        return 'bg-[#233024] text-[#C8FFAE]';
      case 'deploying':
        return 'bg-[#232730] text-[#AECAFF]';
      case 'failed':
        return 'bg-[#301E1E] text-[#FF9A9A]';
      default:
        return 'bg-[#232730] text-[#AECAFF]';
    }
  };

  const toggleMetadata = () => {
    setMetadataVisible(!metadataVisible);
  };

  return (
    <div className='bg-[#0B0B20] rounded-lg p-6 border border-white/10 space-y-6'>
      <div className='flex justify-between items-center'>
        <h2 className='text-3xl font-normal text-white'>{name}</h2>
        <Badge className={getCustomStatusClass(status)}>
          {status.toUpperCase()}
        </Badge>
      </div>

      <div className='space-y-2'>
        <div className="flex items-center space-x-2">
          <p
            className="text-sm text-blue-300 hover:text-blue-400 underline cursor-pointer"
            onClick={() => window.open(full_url, '_blank')}
          >
            {url}
          </p>
          <button
            onClick={handleCopyUrl}
            className="p-1 text-white bg-[#13132E] rounded-full hover:bg-[#1a1a40] transition"
            aria-label="Copy URL"
          >
            <IoMdCopy />
          </button>
        </div>
        <p className="text-sm text-white/50">
          Deployment Time: {deployment_time.toFixed(2)} seconds
        </p>
      </div>

      <div className='space-y-4'>
        <div className='p-4 bg-[#0F0F2D] border border-white/10 rounded-lg'>
          <p className="text-white">
            <span className="font-thin">Deployment Status: </span>
            <Badge className={getCustomDeploymentStatusClass(deployment_status)}>
              {deployment_status}
            </Badge>
          </p>

          <button
            onClick={toggleMetadata}
            className="mt-4 text-blue-300 underline text-sm hover:text-blue-400"
          >
            {metadataVisible ? 'Hide Metadata' : 'Show Metadata'}
          </button>

          {metadataVisible && (
            <pre className="bg-[#13132E] text-white p-4 mt-2 rounded-lg overflow-auto text-sm">
              {JSON.stringify(metadata, null, 2)}
            </pre>
          )}
        </div>
      </div>
    </div>
  );
};
