import React, {createContext, useContext} from 'react'
import useUser from '../hooks/auth/useuser'

const StateContext = createContext()


export const ContextProvider = ({children}) => {


    const {user, googleLogin, githubLogin, onLoginFailure, logout, setUser, loading } = useUser()

    return (
        <StateContext.Provider
            value={{
                user,
                setUser,
                googleLogin,
                githubLogin,
                onLoginFailure,
                logout,
                loading
            }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = ()=> useContext(StateContext)