export const storeTokens = (data) => {
    let userTokens = {
        access_token: data.access, refresh_token: data.refresh
    }
    localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, JSON.stringify(userTokens))
}


export const getTokens = () => {
    const tokensStr = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
    if (![null, undefined, "", "{}"].includes(tokensStr)){
        return JSON.parse(tokensStr)
    }
    throw new Error("Token not found")
}


export const clearTokens = () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
}