import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GoogleAuthComponent = ({ login, handleError }) => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="flex items-center justify-between bg-white text-black py-2 px-4 rounded">
        <span className="mr-2">Continue with <span className="font-bold">Google</span></span>
        <GoogleLogin 
          onSuccess={login} 
          onError={handleError} 
          scope="profile" 
          render={(renderProps) => (
            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="flex items-center">
              <img src="/path-to-google-logo.png" alt="Google" className="w-6 h-6" />
            </button>
          )}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleAuthComponent;
