import { useState } from "react";
import React from 'react';
import Info from "../../imgs/icons/Info.svg"


const BORDER_CLASS = "bg-[#0F0F2D] border rounded-[6px] h-[51px]"

const BasicInput = ({ name, placeholder, value, onChange, style }) => {
    return (
        <input
            style={style}
            className={`${BORDER_CLASS} w-full`}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            name={name}
        />
    );
};

export const Button = ({ logo, text, onClick, style, className, children }) => {
    return (
        <button
            className={`flex items-center space-x-2 px-4 py-2 rounded ${BORDER_CLASS} w-1/3 ${className}`}
            onClick={onClick}
            style={style}
        >
            <img src={logo} className="rounded-[20px] h-[20px] w-[20px]" alt="" />
            <p className="text-white">{text}</p>
            {children}
        </button>
    );
}

export const CheckedInput = ({placeholder, value, name, onChange})=>{
    return (
        <div className="flex w-1/2 justify-end items-center">
            <input 
                className="border rounded-[5px] h-[24px] w-[24px] bg-[#0A0A22] cursor-pointer"
                type='checkbox'
                checked={value ? value : false}
                onChange={(e)=> onChange(name, e.target.checked)}
            />
        </div>
        
    )
}


export const LogoInput = ({logo, placeholder, value}) => {
    return (
        <div className={BORDER_CLASS}>
            <img src={logo} alt={`${value} icon`}></img>
            <BasicInput placeholder={placeholder} value={value}/>
        </div>
    )
}


export const TitleInput = ({ Title, logo, placeholder, style, name, value, onChange, className }) => {
    return (
        <div className={`${className} mb-4`}>
            <div className="text-white mb-2 w-full flex items-center">
                {typeof logo === 'function' ? (
                    React.createElement(logo, { className: "mr-2 text-gray-400" })
                ) : (
                    <img src={logo} alt={`${Title} logo`} className="mr-2 h-5 w-5" />
                )}
                <p className="text-sm font-medium">{Title}</p>
            </div>
            <BasicInput 
                placeholder={placeholder} 
                value={value} 
                style={style} 
                name={name} 
                onChange={onChange}
                className="w-full px-3 py-2 text-sm bg-[#1E1E2D] border border-gray-700 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
        </div>
    );
};


export const TextLeftInput = ({ text, icon, tooltip, className, style, children }) => {
    return (
        <div className={`${BORDER_CLASS} ${className}`} style={style}>
            <div className="flex justify-between m-4">
                <div className="w-full flex items-center relative">
                    <p className="text-xs 2xl:text-base">{text}</p>
                    <Tooltip icon={icon} text={tooltip} className="ml-2"/>
                </div>
                {children}
            </div>
        </div>
    );
};


export const Tooltip = ({ icon, text, className }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    const handleMouseEnter = () => setTooltipVisible(true);
    const handleMouseLeave = () => setTooltipVisible(false);

    return (
        <div className={`relative inline-block cursor-pointer ${className}`}>
            <img
                src={icon}
                alt="Info"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            {isTooltipVisible && (
                <span className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 p-2 text-sm text-white bg-gray-800 rounded shadow-lg z-50 min-w-[200px] text-center">
                    {text}
                </span>
            )}
        </div>
    );
};


export const SelectInput = ({
    label,
    name,
    options,
    value,
    onChange,
    placeholder = "Select an option",
    style,
    className,
    disabledOptions = [],
    labelTooltip = "", // Texte du tooltip à afficher à côté du label
}) => {
    return (
        <div className={`${className} mb-4 relative`}>
            <div className="flex items-center">
                {label && (
                    <label htmlFor={name} className="text-white mb-2 block text-sm font-medium">
                        {label}
                    </label>
                )}
                {labelTooltip && (
                    <Tooltip icon={Info} text={labelTooltip} className="ml-2" />
                )}
            </div>
            <div className="relative">
                <select
                    id={name}
                    name={name}
                    value={value}
                    onChange={(event) => onChange(event.target.name, event.target.value)}
                    className={`${BORDER_CLASS} w-full text-white appearance-none px-4 py-2 cursor-pointer`}
                    style={style}
                >
                    <option value="" disabled>{placeholder}</option>
                    {options.map((option) => (
                        <option 
                            key={option.value} 
                            value={option.value} 
                            disabled={disabledOptions.includes(option.value)} 
                            className="text-white"
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg
                        className="w-4 h-4 fill-current text-gray-500"
                        viewBox="0 0 20 20"
                    >
                        <path d="M7.293 9.293a1 1 0 011.414 0L10 10.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z" />
                    </svg>
                </div>
            </div>
        </div>
    );
};
