import { useState, useEffect } from "react";
import { FaGithub, FaTelegram, FaDiscord, FaExternalLinkAlt } from 'react-icons/fa';
import { FiLoader, FiUploadCloud, FiTrash2, FiEye } from "react-icons/fi";
import { motion } from 'framer-motion';
import { toast } from "react-toastify";
import projectService from "../../services/deployments/project";
import xLogo from "../../imgs/icons/x_logo.svg";
import useLaunchAppchain from "hooks/deployments/uselaunchappchain";

const ProjectCustomization = ({ projectId, project, onProjectUpdate }) => {
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState(project.logo_url);
    const [socialLinks, setSocialLinks] = useState({
        github: project.github_link ?? '',
        x: project.x_link ?? '',
        telegram: project.telegram_link ?? '',
        discord: project.discord_link ?? ''
    });
    const [projectLink, setProjectLink] = useState(project.metadata?.project_link ?? "");
    const { projectVisibilities } = useLaunchAppchain();
    const [loading, setLoading] = useState(false);
    const [projectVisibility, setProjectVisibility] = useState(project.visibility);

    useEffect(() => {
        // Update the UI if there's a logo URL or new social links
        setLogoUrl(project.logo_url);
        setSocialLinks({
            github: project.github_link ?? '',
            x: project.x_link ?? '',
            telegram: project.telegram_link ?? '',
            discord: project.discord_link ?? ''
        });
    }, [project]);

    const uploadLogo = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                setLoading(true);
                setLogo(file);
                await projectService.addLogo(projectId, file);
                onProjectUpdate();
                toast.success("Logo uploaded successfully!");
            } catch (error) {
                toast.error("Failed to upload logo, please try again.");
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDeleteLogo = () => {
        setLogo(null);
        setLogoUrl(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSocialLinks(prevLinks => ({
            ...prevLinks,
            [name]: value
        }));
    };

    const saveProject = async () => {
        setLoading(true);
        try {
            const data = {
                metadata: {
                    socials: socialLinks,
                    project_link: projectLink
                },
                visibility: projectVisibility
            };
            await projectService.update(projectId, data);
            onProjectUpdate();
            toast.success('Changes saved successfully!');
        } catch (error) {
            toast.error("Something went wrong. Please try later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <motion.div 
            className="p-4 bg-[#0A0A22] rounded-lg mb-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className="mb-6 flex flex-col items-center">
                <div className="relative w-40 h-40 border-2 border-dashed border-white/10 rounded-full flex items-center justify-center hover:border-indigo-500">
                    {logoUrl ? (
                        <img src={logoUrl} alt="Project Logo" className="rounded-full object-cover w-full h-full" />
                    ) : (
                        <label 
                            htmlFor="logo-upload" 
                            className="absolute inset-0 flex flex-col items-center justify-center cursor-pointer text-white hover:text-gray-300"
                        >
                            <FiUploadCloud className="text-4xl mb-2" />
                            <span className="text-sm">Upload Logo</span>
                            <input 
                                type="file" 
                                id="logo-upload" 
                                onChange={uploadLogo}
                                className="hidden"
                            />
                        </label>
                    )}
                    {logoUrl && (
                        <button 
                            className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-500 focus:outline-none"
                            onClick={handleDeleteLogo}
                            aria-label="Delete logo"
                        >
                            <FiTrash2 className="text-xl" />
                        </button>
                    )}
                </div>
            </div>

            <div className="space-y-4">
                <div className="flex items-center space-x-3">
                    <FiEye className="text-white text-xl"/>
                    <select
                        id="visibility"
                        value={projectVisibility}
                        onChange={(e) => setProjectVisibility(e.target.value)}
                        className="w-full px-4 py-2 bg-[#12121C] border border-white/20 rounded-md text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 transition duration-200"
                    >
                        {projectVisibilities.map(({ value, label }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex items-center space-x-3">
                    <FaExternalLinkAlt className="text-white text-xl" />
                    <input 
                        type="text" 
                        name="project_link"
                        value={projectLink}
                        onChange={(e) => setProjectLink(e.target.value)}
                        placeholder="Project App Link"
                        className="w-full px-4 py-2 bg-[#12121C] border border-white/20 rounded-md text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 transition duration-200"
                    />
                </div>
                {["github", "x", "telegram", "discord"].map((platform) => (
                    <div key={platform} className="flex items-center space-x-3">
                        {platform === "x" ? (
                            <img src={xLogo} alt="X" width="20" />
                        ) : platform === "github" ? (
                            <FaGithub className="text-white text-xl" />
                        ) : platform === "telegram" ? (
                            <FaTelegram className="text-white text-xl" />
                        ) : (
                            <FaDiscord className="text-white text-xl" />
                        )}
                        <input 
                            type="text" 
                            name={platform}
                            value={socialLinks[platform]}
                            onChange={handleInputChange}
                            placeholder={`${platform.charAt(0).toUpperCase() + platform.slice(1)} Link`}
                            className="w-full px-4 py-2 bg-[#12121C] border border-white/20 rounded-md text-white placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 transition duration-200"
                        />
                    </div>
                ))}
            </div>

            <button 
                onClick={saveProject}
                className={`mt-6 w-full px-4 py-2 text-base font-normal rounded-md transition-all ${loading ? 'bg-gray-600 cursor-not-allowed' : 'bg-white text-black'} flex items-center justify-center`}
                disabled={loading}
            >
                {loading ? <FiLoader className="animate-spin mr-2"/> : 'Save'}
            </button>
        </motion.div>
    );
};

export default ProjectCustomization;
