import React from 'react';

const Requests = () => {
    return (
        <div className="bg-[#0A0A22] p-6 rounded-lg border border-borderc min-w-[800px] max-w-[800px]">
            <h2 className="text-3xl text-white70 my-4">Requests</h2>
            <p className="text-gray-400">Details about requests...</p>
        </div>
    );
};

export default Requests;
