// LoginRedirect.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from "../../settings/axios";
import {useStateContext} from "../../contexts/ContextProvider";


const LoginRedirect = () => {
  const navigate = useNavigate();
  const {logout} = useStateContext();

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
            logout();
            navigate("/login");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [logout, navigate]);

  return null;
};

export default LoginRedirect;
