import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { motion } from 'framer-motion';
import useAppchains from "../../hooks/deployments/useappchains";
import AnimatedContainer from '../../components/AnimatedContainer';
import AnimatedSkeleton from '../../components/AnimatedSkeleton';
import CreateProjectButton from "../../components/deployments/CreateProjectButton";
import AppchainsContainer from 'containers/AppChains';
import InviteUsers from "../../components/deployments/InviteUsers";
import LogsDisplay from '../../components/deployments/LogsDisplay';
import FaucetComponent from '../../components/deployments/Faucet';
import AddChain from 'components/deployments/AddChain';
import ProjectCustomization from "../../components/deployments/ProjectCustomization";
import PrimaryButton from "../../components/PrimaryButton";
import argentLogo from "../../imgs/argent.svg";
import { mainnet } from "@starknet-react/chains";
import { StarknetConfig, InjectedConnector, publicProvider } from '@starknet-react/core';
import braavosLogo from "../../imgs/braavos.svg";


const connectors = [
    new InjectedConnector({options: {id: "braavos", icon: braavosLogo, name: "Braavos"}}),
    new InjectedConnector({options: {id: "argentX", icon: argentLogo}})
]

function rpc(url) {
    return {
      nodeUrl: url
    }
  }

const ProjectDetail = () => {
    const { projectId } = useParams();
    const {
        project, fetchProject, loading, appChains, setAppChains, fetchAppChains
    } = useAppchains(projectId);
    const [isCustomizationOpen, setIsCustomizationOpen] = useState(false);

    const openCustomizationModal = () => setIsCustomizationOpen(true);
    const closeCustomizationModal = () => setIsCustomizationOpen(false);

    if (loading) {
        return (
            <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-col p-8 md:p-16">
                <Header title="Loading..." />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {[...Array(3)].map((_, index) => (
                        <AnimatedSkeleton key={index} />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <AnimatedContainer>
            <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white p-8 md:p-16 flex">
                <div className="mr-8 mt-2">
                    <BackButton />
                </div>
                <div className="flex-grow flex flex-col">
                    <Header title={project?.name || 'Project Name'}>
                        <div className="flex space-x-3">
                            <div className="h-10">
                                <CreateProjectButton projectId={projectId} />
                            </div>
                            <div className="h-10">
                                <InviteUsers project={project} />
                            </div>
                            <div className={"h-10"}>
                                <PrimaryButton onClick={openCustomizationModal}>
                                    Customize Project
                                </PrimaryButton>
                            </div>
                        </div>
                        </Header>
                        <div className="grid grid-cols-2 gap-6 mt-6">
                            <AppchainsContainer appChains={appChains} setAppChains={setAppChains} fetchAppChains={fetchAppChains}/>
                            <div>                   
                                <StarknetConfig
                                        chains={[mainnet]}
                                        connectors={connectors}
                                        provider={publicProvider()}
                                    >
                                    <FaucetComponent appchain={appChains.length > 0 ? appChains[0] : null} />
                                    <AddChain appchain={appChains.length > 0 ? appChains[0] : null} />
                                </StarknetConfig>
                            </div>
                            <LogsDisplay appChain={appChains.length > 0 ? appChains[0] : null} />
                        </div>
                </div>
            </div>

            {isCustomizationOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <motion.div
                        className="p-4 bg-[#0A0A22] border border-white/10 rounded-lg max-w-lg w-full"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="flex justify-between items-center mb-2">
                            <h2 className="text-xl font-semibold text-white">Customize Project</h2>
                            <button onClick={closeCustomizationModal} className="text-gray-400 hover:text-gray-300">
                                ✕
                            </button>
                        </div>
                        <ProjectCustomization project={project} projectId={projectId} onProjectUpdate={fetchProject}/>
                    </motion.div>
                </div>
            )}
        </AnimatedContainer>
    );
};

const Header = ({ title, children }) => (
    <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-2">
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
            <motion.h1
                className="text-2xl md:text-3xl font-light"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                {title}
            </motion.h1>
        </div>
        {children}
    </div>
);

const BackButton = () => (
    <motion.button
        className="flex items-center text-white space-x-2 focus:outline-none hover:text-gray-300 transition-colors duration-200"
        onClick={() => window.history.back()}
        whileHover={{ scale: 1 }}
    >
        <IoChevronBack className="text-xl" />
        <span>Back</span>
    </motion.button>
);

export default ProjectDetail;
