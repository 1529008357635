import {BaseService} from "../core/base";

class AppchainService extends BaseService{
    async list(){
        const resp = await this.apiClient.get("/deployments/appchains");
        return resp.data;
    }
}

const appchainService = new AppchainService();
export default appchainService;
