import projectKatanaService from "../../services/deployments/katana";
import projectMadaraService from "../../services/deployments/madara";
import toriiService from "../../services/deployments/torii";
import quasmCompassService from "../../services/deployments/quasm_compass";


export const getAppchainService = (serviceName) => {
    if (serviceName === "katana") return projectKatanaService;
    if (serviceName === "madara") return projectMadaraService;
    if (serviceName === "torii") return toriiService;
    if (serviceName === "quasm_compass") return quasmCompassService
}
