import React from 'react';
import { motion } from 'framer-motion';

const ConfirmModal = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
      <motion.div
        initial={{ y: '5vh', opacity: 1 }}
        animate={{ y: '0', opacity: 1 }}
        exit={{ y: '100vh', opacity: 0 }}
        transition={{ stiffness: 10, damping: 30, duration: 0.3 }}
        className="border border-white/10 relative p-8 bg-[#0A0A22] w-full max-w-md m-auto flex-col flex rounded-lg"
      >
        <div className="text-center text-3xl font-normal mb-2">{title}</div>
        <div className="text-center text-sm mb-4">{message}</div>
        <div className="flex justify-between space-x-4 mt-4">
          <button
            onClick={onCancel}
            className="w-full bg-white/5 text-base hover:bg-white/20 text-white font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="w-full bg-white text-base hover:bg-opacity-90 text-black font-normal py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Confirm
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default ConfirmModal;
