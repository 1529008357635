import React from 'react';

const SearchBar = ({ onSearch }) => {
  return (
    <div className="relative flex-grow">
      <input
        type="text"
        placeholder="Search appchains..."
        className="w-full bg-[#0B0B20] text-white border border-white/10 rounded-lg py-2 px-4 pl-10 focus:outline-none focus:ring-0 focus:border-white/20"
        onChange={(e) => onSearch(e.target.value)}
      />
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg className="h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </svg>
      </div>
    </div>
  );
};

export default SearchBar;