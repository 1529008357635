import { BaseService } from "../core/base";

class ImageService extends BaseService{
    
    async getKatanaImages(){
        const resp = await this.apiClient.get(
            "/refs/images/?name=Katana"
        )
        return resp.data;
    }
}

const imageService = new ImageService();
export default imageService;
