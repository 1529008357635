import {BaseService} from "../core/base";


class AuthService extends BaseService{
  
  async sendGoogleToken(token) {
    try {
      const response = await this.apiClient.post(
          `/api/auth/social/google/`,
          { access_token: token , id_token: token}
      );
      return response.data;
    } catch (error) {
      console.error('Failed to send Google token:', error);
      throw error;
    }
  }

  async sendGitHubToken(code) {
    try {
      const response = await this.apiClient.post(
          `/api/auth/social/github/`, {code: code}
      );
      return response.data;
    } catch (error) {
      console.error('Failed to send GitHub token:', error);
      throw error;
    }
  }

  async getProfile(){
    const response = await this.apiClient.get("/api/me");
    return response.data;
  }

  async applyFreePlan(){
    const response = await this.apiClient.post("/api/auth/apply-free-plan")
    return response.data;
  }

  async refreshToken(refreshToken){
    const response = await this.apiClient.post(
        "/api/auth/token/refresh/", {refresh: refreshToken}
    )
    return response.data;
  }
}

export const authService = new AuthService();

export default authService;
