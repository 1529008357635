import React from 'react';
import { motion } from 'framer-motion';
import { RiCloudFill } from '@remixicon/react';
import DetailedAppchainCard from 'components/deployments/DetailedAppchainCard';

  

export default function AppchainsContainer({ appChains, refresh, setAppChains, fetchAppChains }) {

    const handleAppChainDelete = (deletedAppchainId) => {
        // todo implement
    };

    if (!appChains || appChains.length === 0) {
        return (
            <div className="text-gray-400 text-center py-10">
                No appchains available.
            </div>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, staggerChildren: 0.1 }}
        >
            {appChains.map((appChain, index) => (
                <motion.div
                    key={appChain.id}
                    className="w-full"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                    <DetailedAppchainCard
                        icon={RiCloudFill}
                        appChain={appChain}
                        setAppChains={setAppChains}
                        onDelete={handleAppChainDelete}
                        onAction={refresh}
                        fetchAppChains={fetchAppChains}
                    />
                </motion.div>
            ))}
        </motion.div>
    );
}
