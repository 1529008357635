import React from 'react';

const Invoices = () => {
    return (
        <div className='min-w-[800px] max-w-[800px] space-y-4'>

            <div className="bg-[#0A0A22] p-6 rounded-lg border border-borderc transform hover:translate-x-2 transition duration-200 hover:bg-[#0F0F2D]">
                <h2 className="text-3xl text-white70 my-4">June 28th</h2>
                <p className="text-gray-400">Spent 30 dollars on appchain hosting</p>
            </div>

            <div className="bg-[#0A0A22] p-6 rounded-lg border border-borderc transform hover:translate-x-2 transition duration-200 hover:bg-[#0F0F2D]">
                <h2 className="text-3xl text-white70 my-4">June 29th</h2>
                <p className="text-gray-400">Spent 50 dollars on appchain hosting</p>
            </div>

            <div className="bg-[#0A0A22] p-6 rounded-lg border border-borderc transform hover:translate-x-2 transition duration-200 hover:bg-[#0F0F2D]">
                <h2 className="text-3xl text-white70 my-4">June 30th</h2>
                <p className="text-gray-400">Spent 20 dollars on appchain hosting</p>
            </div>

        </div>

    );
};

export default Invoices;
