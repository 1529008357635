import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ExploratoryAppchainCard from './ExploratoryAppchainCard';
import SearchBar from '../../components/browse/SearchBar';
import ComingSoonModal from '../../components/browse/ComingSoonModal';
import {useExplorableProjects} from "../../hooks/deployments/useExplorableProjects";
import CreateProjectButton from '../../components/deployments/CreateProjectButton';


const Explore = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const {explorableProjects, setExplorableProjects} = useExplorableProjects();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = explorableProjects.filter(project =>
            project.project_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.stack_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.services_deployed.some(service => service.toLowerCase().includes(searchQuery.toLowerCase()))

        );
        setExplorableProjects(filtered);
    };

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const item = {
        hidden: { opacity: 0, y: 20, filter: 'blur(10px)' },
        show: { 
            opacity: 1, 
            y: 0, 
            filter: 'blur(0px)',
            transition: {
                duration: 0.5
            }
        }
    };

    return (
        <div className='mt-20 max-w-7xl mx-auto space-y-6'>
            <h1 className='text-3xl font-light'>Browse Verified Projects</h1>
            
            {/* Search, filter, and create button */}
            <div className="flex items-center space-x-4">
                <SearchBar onSearch={handleSearch} />
                <CreateProjectButton buttonText='Create an explorable project'/>
            </div>

            {/* Grid of exploratory appchain cards */}
            <motion.div 
                className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'
                variants={container}
                initial="hidden"
                animate="show"
            >
                {explorableProjects.map((project, index) => (
                    <motion.div key={index} variants={item}>
                        <ExploratoryAppchainCard
                            projectName={project.name}
                            projectLink={project.metadata.project_link}
                            logoUrl={project.logo_url}
                            isArchived={project.is_archived}
                            stackName={project.stack_name}
                            services={project.services_deployed}
                            onCopyStack={() => setIsModalOpen(true)}
                        />
                    </motion.div>
                ))}
            </motion.div>

            <ComingSoonModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default Explore;