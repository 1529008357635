import { useState, useEffect } from 'react'
import authService from "../../services/auth/auth";
import {clearTokens} from "../../core/stores/tokens";
import {storeTokens} from "../../core/stores/tokens";


export default function useUser(){

    const [ user, setUser ] = useState(null)
    const [ error, setError ] = useState(null)
    const [loading, setLoading] = useState(true)

    const googleLogin = async (response) => {
        const data = await authService.sendGoogleToken(response.credential)
        storeTokens(data);
        const resp = await authService.getProfile()
        setUser(resp)
      }

    const githubLogin = async (code) => {
        const data = await authService.sendGitHubToken(code)
        storeTokens(data);
        const resp = await authService.getProfile()
        setUser(resp);
    };

    const onLoginFailure = (response) => {
        console.error(response);
    };

    useEffect(() => {
      
        async function getData(){
            setLoading(true)
            try {
                const resp = await authService.getProfile();
                setUser(resp)
            } catch (e){
                setUser(null)
            } finally {
                setLoading(false)
            }
        }
        getData();

    }, [])

    function logout(){
        setUser(null)
        clearTokens();
    }
    
    return {
        user, 
        setUser, 
        logout, 
        error, 
        setError, 
        googleLogin,
        githubLogin,
        onLoginFailure,
        loading
    }

}