function openUrlInNewTab(url) {

    if (typeof url !== 'string' || url.trim() === '') {
        console.error('Invalid URL: URL must be a non-empty string');
        return;
    }

    // Add protocol if missing
    if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
    }

    try {
        new URL(url);
    } catch (e) {
        console.error('Invalid URL: URL format is not correct', e);
        return;
    }

    const newWindow = window.open(url, '_blank');

    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        console.error('Failed to open URL: The new tab was blocked by the browser');
    }
}

export default openUrlInNewTab