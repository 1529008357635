import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '@tremor/react';
import { IoChevronBack } from 'react-icons/io5';
import { IoIosPause } from "react-icons/io";
import { GrPowerReset } from "react-icons/gr";
import { IoMdPlay } from "react-icons/io";
import { FaTools } from "react-icons/fa";
import { SiHiveBlockchain } from "react-icons/si";
import { IoStopSharp } from "react-icons/io5";
import MonitorPageSkeleton from './MonitorPageSkeleton';
import LatencyGauge from "../../components/core/DummyLatency";
import appchainService from "services/deployments/appchain";
import {getStatusTextAndColor} from "../../components/deployments/utils/appchainStatus";
import {useStateContext} from "../../contexts/ContextProvider";


const getButtonsForStatus = (status) => {
    switch (status) {
        case 'Stopped': // Stopped
            return (
                <>
                    <div className='rounded p-3 hover:bg-white03'>
                        <IoMdPlay />
                    </div>
                </>
            );
        case 'Active': // Running
            return (
                <>
                    <div className='rounded p-3 hover:bg-white03'>
                        <GrPowerReset />
                    </div>
                    <div className='rounded p-3 hover:bg-white03'>
                        <IoIosPause />
                    </div>
                    <div className='rounded p-3 hover:bg-white03'>
                        <IoStopSharp />
                    </div>
                </>
            );
        case 'Error': // Paused
            return (
                <>
                    <div className='rounded p-3 hover:bg-white03'>
                        <GrPowerReset />
                    </div>
                    <div className='rounded p-3 hover:bg-white03'>
                        <IoMdPlay />
                    </div>
                    <div className='rounded p-3 hover:bg-white03'>
                        <IoStopSharp />
                    </div>
                </>
            );
        default:
            return null;
    }
};

const getIconForType = (type) => {
    switch (type) {
        case 'katana':
        case 'madara':
            return <SiHiveBlockchain />;
        case 'quasmcompass':
        case 'saya':
        case 'torii':
            return <FaTools />;
        default:
            return null;
    }
};

export default function Monitor() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const {user} = useStateContext();

    useEffect(() => {

        const fetchAppChains = async () => {
            setData(await appchainService.list());
            setLoading(false);
        }

        fetchAppChains();
    }, []);

    if (loading) {
        return <MonitorPageSkeleton />;
    }

    return (
        <>
            {/* bg */}
            <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-col px-8 py-16 lg:p-16">
                <div className="flex flex-row">
                    <div>
                        <button className="bg-transparent py-2 px-4 text-white flex items-center space-x-2" onClick={() => window.history.back()}>
                            <IoChevronBack />
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="sm:flex sm:items-center sm:justify-between sm:space-x-10">
                            <div>
                                <h3 className="text-3xl text-white font-thin">
                                    Monitor Services
                                </h3>
                                <p className="font-thin text-base leading-6 text-white/80">
                                    Overview of all the services made by or shared with you.
                                </p>
                            </div>
                        </div>
                    {/* table container */}
                </div>
                <div className="grow max-w-[1400px] ml-24">
                        <Table className="mt-12 rounded">
                            <TableHead className="bg-gray-800 text-white">
                                <TableRow className="text-gray-100">
                                    <TableHeaderCell/>
                                    <TableHeaderCell className="text-white">
                                        Project
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-white">
                                        Name
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-white">
                                        Capacity
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-white">
                                        Plan
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-right text-white">
                                        Date Deployed
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-right text-white">
                                        Deployment Time
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-center text-white">
                                        Latency
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-right text-white">
                                        Status
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-right text-white">
                                        Actions
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="bg-gray-900 text-gray-100">
                                {data.map((item) => {
                                    const { text, color } = getStatusTextAndColor(item.status);
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell>{getIconForType(item.type)}</TableCell>
                                            <TableCell>{item.project_name}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>
                                                {Math.round(Math.random() * 100)} %
                                            </TableCell>
                                            <TableCell>{user.current_plan}</TableCell>
                                            <TableCell className="text-right">{item.date_deployed_display}</TableCell>
                                            <TableCell className="text-right">{item.deployment_time.toFixed(2)} s</TableCell>
                                            <TableCell className="text-right"><LatencyGauge/></TableCell>
                                            <TableCell className={`text-right ${color}`}>{text}</TableCell>
                                            <TableCell className="text-right">
                                                <div className="flex flex-row justify-end">
                                                    {getButtonsForStatus(item.status)}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>

                {/* <div className="flex flex-col">
                    <h1 className="text-white90">Legend</h1>
                    <div className="text-white70 flex flex-row items-center space-x-2">
                        <SiHiveBlockchain />
                        <span>- Appchain</span>
                    </div>
                    <div className="text-white70 flex flex-row items-center space-x-2">
                        <FaTools />
                        <span>- Tool</span>
                    </div>
                </div> */}
            </div>
        </>
    );
}
