import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import projectKatanaService from "../../services/deployments/katana";
import {DetailAppchainCard} from "../../components/deployments/DetailAppchainCard";
import {DataTable} from "../../components/core/BaseDataTable";
import {TbWorldCode} from "react-icons/tb";
import { LiaFileContractSolid } from "react-icons/lia";
import { RiRefund2Line } from "react-icons/ri";


export const KatanaAppDetail = () => {

    const {projectId, katanaId} = useParams();
    const [katanaApp, setKatanaApp] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchKatanaApp = async () => {
          try {
            const katanaData = await projectKatanaService.retrieve(projectId, katanaId);
            setKatanaApp(katanaData);
          } catch (err) {
              console.log(err);
          } finally {
            setLoading(false);
          }
        };

        fetchKatanaApp();

    }, [projectId, katanaId]);


    return (
        <div>
            {!loading && katanaApp ? (
                <div className="p-6 bg-gray-100 min-h-screen pt-20" style={{ backgroundColor: '#020215' }}>
                    <DetailAppchainCard appchain={katanaApp}/>
                    <DataTable
                        title="World Contract"
                        columns={['address']}
                        data={katanaApp.contract_addresses}
                        icon={TbWorldCode}
                    />
                    <DataTable
                        title="Deployed Contracts"
                        columns={['type', 'address', 'class_hash']}
                        data={katanaApp.contracts}
                        icon={LiaFileContractSolid}
                    />
                    <DataTable
                        title="Prefunded Accounts"
                        columns={['address', 'private_key', 'public_key']}
                        data={katanaApp.accounts}
                        icon={RiRefund2Line}
                    />
                </div>
                ) : (
                    <p>No katana app</p>
                )
            }
        </div>

    )
}


export default KatanaAppDetail;