import {useEffect, useState, useMemo, useCallback} from "react";

import projectService from "../../services/deployments/project";
import imageService from 'services/refs/image';

import {useNavigate} from "react-router-dom";


const katanaMetaDataDefault = {
    "is_gas_fee_disabled": false,
    "block_time": "",
    "chain_id": "KATANA",
    "seed": "1",
    "accounts": 10,
    "is_auto_mining_enabled": true,
    "is_transaction_validation_enabled": false
}


const madaraMetaDataDefault = {
    "is_gas_fee_disabled": false,
    "block_time": "10",
    "chain_name": "MADARA",
    "chain_id": "MADARA",
    "network": "DEVNET"
}

export default function useLaunchAppchain() {

    const defaultError = {
        "projectName": "",
        "permissionDenied": false,
    }

    const [selectedFramework, setSelectedFramework] = useState("madara")
    const [projectName, setProjectName] = useState("")
    const [projectVisibilities, setProjectVisibilities] = useState([]);
    const [projectVisibility, setProjectVisibility] = useState(null);
    const [metaData, setMetaData] = useState(madaraMetaDataDefault)
    const [selectedServices, setSelectedServices] = useState({
        "blockExplorer": false,
        "torii": false
    })
    const [error, setError] = useState(defaultError)
    const [katanaImages, setKatanaImages] = useState([])
    const [selectedVersion, setSelectedVersion] = useState('')
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchVisibilityStatus = async () => {
            const visibilities = (await projectService.getVisibilityStatus()).status;
            setProjectVisibilities(visibilities);
            setProjectVisibility(visibilities[0].value)
        }

        fetchVisibilityStatus();
    }, []);

    useEffect(() => {
        async function fetchKatanaImages() {
            try {
                const data = await imageService.getKatanaImages()
                setKatanaImages(data)
                setSelectedVersion(data[data.length - 1].version)
            } catch (e) {
                console.log(e)
            }
        }

        fetchKatanaImages()
        return () => {
            setKatanaImages([])
        }

    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(defaultError)
        if (!projectName) {
            setError({
                ...error,
                projectName: "You must have a Project Name"
            })
            return
        }

        try {
            setIsSubmitting(true)
            const data = await projectService.createAppchain(selectedFramework, {
                project: {
                    name: projectName, visibility: projectVisibility
                },
                version: selectedVersion, metadata: metaData
            })
            navigate("/projects/" + data.project);
        } catch (err) {
            if (err.response.status === 403) {
                setError({
                    ...error,
                    permissionDenied: true
                });
            } else if (err.response.status === 400){
                setError({
                    ...error, metadata: err.response.data.detail
                })
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const baseItems = useMemo(() => {
        return selectedFramework === "katana" ? [
        `Built on ${selectedFramework}`,
        `Block Time: ${metaData.block_time} milliseconds`,
        `Gas Fee Enabled: ${!metaData.is_gas_fee_disabled}`,
        `ChainID: ${metaData.chain_id}`,
        `Auto Mining: ${metaData.is_auto_mining_enabled}`,
        `Transaction Validation: ${metaData.is_transaction_validation_enabled}`,
        `Prefunded Accounts: ${metaData.accounts}`,
        "On-Demand Discord support"
      ]: [
        `Built on ${selectedFramework}`,
        `Block Time: ${metaData.block_time} milliseconds`,
        `Gas Fee Enabled: ${!metaData.is_gas_fee_disabled}`,
        `ChainID: ${metaData.chain_id}`,
        "On-Demand Discord support"
      ]
    }, [selectedFramework, metaData]);
    
    const [appendedItems, setAppendedItems] = useState([]);

    const appendItem = useCallback((newItem) => {
        setAppendedItems(prevItems => [...prevItems, newItem]);
    }, []);

    const summaryItems = useMemo(() => [...baseItems, ...appendedItems], [baseItems, appendedItems]);

    const changeAppchainFramework = (appchainFramework) => {
        setSelectedFramework(appchainFramework);
        const choosenMetadata = appchainFramework === "katana" ? katanaMetaDataDefault : madaraMetaDataDefault;
        setMetaData(choosenMetadata);
    }


    return {
        selectedFramework,
        setSelectedFramework,
        projectName,
        setProjectName,
        projectVisibilities,
        projectVisibility,
        setProjectVisibility,
        metaData,
        setMetaData,
        error,
        setError,
        katanaImages,
        setKatanaImages,
        selectedVersion,
        setSelectedVersion,
        summaryItems,
        appendItem,
        handleSubmit,
        selectedServices,
        setSelectedServices,
        isSubmitting,
        changeAppchainFramework
    }
}