import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const truncate = (text, { start = 6, end = 4, mask = '...' } = {}) => {
  if (!text) return '';
  return `${text.slice(0, start)}${mask}${text.slice(-end)}`;
};

const truncatePrivateKey = () => '********';

const identity = (value) => value;

const truncateFuncMap = {
  class_hash: (text) => truncate(text),
  address: (text) => truncate(text),
  type: identity,
  private_key: truncatePrivateKey,
  public_key: (text) => truncate(text),
};

export const DataTable = ({ title, columns, data, icon: Icon }) => {
  const showCopyToast = (copiedText) => {
    toast.success(`'${copiedText}' a été copié dans le presse-papiers`, {
      position: 'top-right',
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="overflow-x-auto my-5">
      <ToastContainer />
      <div className="flex items-center mb-3">
        {Icon && <Icon className="text-white mr-2" size={24} />}
        <h2 className="text-xl font-thin text-white">{title}</h2>
      </div>
      <table className="table-auto w-full text-left whitespace-no-wrap">
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col} className="px-4 py-2 bg-[#0F0F2D] border border-white/20 text-white text-base font-normal">
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="hover:bg-gray-700 bg-[#0A0A22] text-sm">
              {columns.map((col) => (
                <td key={col} className="border border-white/20 px-4 py-2 text-white/80">
                  {item[col] ? (
                    <CopyToClipboard text={item[col]} onCopy={() => showCopyToast(item[col])}>
                      <span className="cursor-pointer" title={item[col]}>
                        {truncateFuncMap[col] ? truncateFuncMap[col](item[col]) : item[col]}
                      </span>
                    </CopyToClipboard>
                  ) : (
                    'N/D'
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
