import React from 'react';
import { HiCubeTransparent } from "react-icons/hi2";
import { MdOutlineArrowOutward } from "react-icons/md";
import { PiCopySimple } from "react-icons/pi";
import openUrlInNewTab from "../../utils/openUrl";

const ExploratoryAppchainCard = ({ projectName, projectLink, logoUrl, isArchived, stackName, services, onCopyStack }) => {
    const getStatusStyle = (isArchived) => {
        return isArchived ? 'bg-[#232730] text-[#AECAFF]' : 'bg-[#233024] text-[#C8FFAE]';
    };

    return (
        <div className='bg-[#0B0B20] rounded-lg p-6 border border-white/10 space-y-6'>
            <div className='flex flex-row justify-between items-center'>
                <div className='flex flex-row items-center space-x-3'>
                    <img 
                        src={logoUrl} 
                        alt="Project Logo" 
                        className='w-10 h-10 rounded-full border border-white/10 object-cover' 
                    />
                    <h1 className='text-lg font-semibold text-white'>{projectName}</h1>
                </div>
                <div className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusStyle(isArchived)}`}>
                    {isArchived ? "Archived" : "Live"}
                </div>
            </div>

            <div className='flex flex-row justify-between items-start'>
                <div className='flex flex-col'>
                    <h2 className='text-sm font-light text-white/50'>Deployed using</h2>
                    <h1 className='text-base font-medium text-white/80'>{stackName}</h1>
                </div>

                <div className='flex flex-col items-end'>
                    <h2 className='text-sm font-light text-white/50'>Added Services <span>({services.length})</span></h2>
                    <div className='flex flex-row items-center space-x-2'>
                        <HiCubeTransparent className='text-lg text-white/80' />
                        <h1 className='text-base font-medium text-white/80'>{services[0]} +{services.length - 1}</h1>
                    </div>
                </div>
            </div>

            <div className='flex flex-row justify-between mt-4 space-x-3'>
                <button 
                onClick={()=>openUrlInNewTab(projectLink)}
                className="h-10 w-1/2 bg-[#13132E] text-white border border-white/10 text-base rounded hover:bg-[#1a1a40] transition duration-200 ease-in-out flex items-center justify-center relative group">
                    View App
                    <MdOutlineArrowOutward className="ml-2 transition-transform duration-200 group-hover:translate-x-1" />
                </button>
                <button
                    className="h-10 w-1/2 bg-white text-black text-base rounded hover:bg-gray-200 transition duration-200 ease-in-out flex items-center justify-center relative group"
                    onClick={onCopyStack}
                >
                    Copy Stack
                    <PiCopySimple className="ml-2 transition-transform duration-200 group-hover:translate-x-1" />
                </button>
            </div>

            <div className='mt-4 text-center'>
                <h2 className='text-sm font-light text-white/50'>Contact Us</h2>
                <a href={`mailto:support@quasm.io`} target={"_blank"}
                   className='text-base font-medium text-[#C8FFAE] underline'>
                    {/*{process.env.REACT_APP_SUPPORT_EMAIL}*/}
                    support@quasm.io
                </a>
                <p className='text-xs font-light text-white/50 mt-1'>
                    Create channel and join the community
                </p>
            </div>
        </div>
    );
};

export default ExploratoryAppchainCard;
