import React, { useEffect, useState } from 'react';
import githubLogo from "../../imgs/githubLogo.svg";
import { useStateContext } from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

const GitHubAuthComponent = () => {
    const { githubLogin } = useStateContext();
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false); // État pour empêcher le double traitement

    const handleLogin = () => {
        window.location.href = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=user:email,repo:read`;
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code && !isProcessing) {
            setIsProcessing(true); // Empêcher de relancer le traitement
            githubLogin(code).then(() => {
                setIsProcessing(false);
                navigate("/");
            }).catch(() => {
                setIsProcessing(false);
            });
        }
    }, [githubLogin, navigate, isProcessing]);

    return (
        <div className="flex items-center bg-white text-black py-2 rounded">
            <button
                onClick={handleLogin}
                className="bg-white-500 text-dark py-2 px-4 rounded"
            >
                <div className="flex items-center justify-between w-100">
                    <div>Continue with <span className="font-bold">GitHub</span></div>
                    <img src={githubLogo} alt="GitHub" className="w-6 h-6 ml-80"/>
                </div>
            </button>
        </div>
    );
};

export default GitHubAuthComponent;
