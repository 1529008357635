import React from 'react';

import { useNavigate } from 'react-router-dom';
import PrimaryButton from "../PrimaryButton";



const CreateProjectButton = ({ buttonText = "Launch new AppChain 🚀" }) => {

  const navigate = useNavigate();

  return (
    <div>
      <PrimaryButton
        onClick={ () => navigate("/launch-appchain")}
        className='text-sm h-10'
      >
        {buttonText}
      </PrimaryButton>
    </div>
  );
};

export default CreateProjectButton;
