export const DropdownButton = ({ onClick, icon: Icon, children }) => (
    <button
      onClick={onClick}
      className="w-full flex items-center text-white text-left px-2 py-1 hover:bg-[#282865] rounded transition duration-200 ease-in-out"
      aria-label={children}
    >
      <Icon className="mr-2" />
      {children}
    </button>
  );
  
export default DropdownButton;    