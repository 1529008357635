import axios from 'axios'
import {clearTokens, getTokens, storeTokens} from "../core/stores/tokens";
import authService from "../services/auth/auth";


const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});

api.interceptors.request.use(
    config => {
        try {
            let tokens = getTokens();
            config.headers["Authorization"] = `Bearer ${tokens.access_token}`
        } catch (e) {
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);


api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                let tokens = getTokens()
                const newToken = await authService.refreshToken(tokens.refresh_token);
                tokens.access_token = newToken.access
                storeTokens(tokens)
                return api(originalRequest);
            } catch (err) {
                console.error('Token refresh failed:', err);
                clearTokens();
            }
        }
        return Promise.reject(error);
    }
);


export default api