import {BaseService} from "../core/base";
import {applyMixin} from "../../core/reducers/applyMixin";
import {DeployableServiceMixin} from "./deployableMixin";
import { FaucetMixin } from "./faucetMixin";

class ProjectKatanaService extends BaseService{
    baseUri = "/deployments/projects/<projectId>/katana/";

    getUri(projectId){
        return this.baseUri.replace("<projectId>", projectId);
    }

}

export const projectKatanaService = new ProjectKatanaService();

applyMixin(projectKatanaService, DeployableServiceMixin);
applyMixin(projectKatanaService, FaucetMixin);

export default projectKatanaService;
