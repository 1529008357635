import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import useUser from "../../hooks/auth/useuser";

const RequireAlphaSignup = () => {
    const { user, loading } = useUser();  // Accéder aux informations de l'utilisateur
    const navigate = useNavigate();

    React.useEffect(() => {
        if (
            user && (!user.is_subscribed || user.current_plan == null)
        ) {
            navigate("/alpha-signup");
        }
    }, [user, navigate]);

    if (loading){
        return null;
    }
    return <Outlet />;
};

export default RequireAlphaSignup;
