import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from "./StripeCheckoutForm";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const StripeCheckout = ({plan}) => (
    <Elements stripe={stripePromise}>
        <StripeCheckoutForm plan={plan}/>
    </Elements>
)

export default StripeCheckout;

