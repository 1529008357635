import {useParams} from "react-router-dom";
import StripeCheckout from "../../components/billing/StripeCheckout";

const StripePayment = () => {
  const { plan } = useParams();

  return (
      <div className="mt-10">
          <StripeCheckout plan={plan}/>
      </div>
  )
}

export default StripePayment;
