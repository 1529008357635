import React, { useState } from 'react';
import { Link } from "react-router-dom";
import KatanaSpecs from 'components/deployments/KatanaSpecs';
import MadaraSpecs from 'components/deployments/MadaraSpecs';
import FrameworkVersion from "components/deployments/FrameworkVersion";
import { Button, Tooltip } from "components/deployments/LaunchInputs";
import MadaraLogo from "../../imgs/icons/madara.png";
import KatanaLogo from "../../imgs/tools/katana.svg";
import ToriiLogo from "../../imgs/tools/torii.svg";
import quasmLogo from "../../imgs/quasm_trans.png";
import { FaEdit, FaCheckCircle } from "react-icons/fa";
import Info from "../../imgs/icons/Info.svg";
import useLaunchAppchain from 'hooks/deployments/uselaunchappchain';
import { Switch } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';


export default function LaunchAppchain() {
    const {
        selectedFramework, projectName, setProjectName,
        setProjectVisibility, projectVisibilities, metaData, setMetaData,
        error, setError, katanaImages, selectedVersion, setSelectedVersion,
        summaryItems, handleSubmit, selectedServices, setSelectedServices, isSubmitting,
        changeAppchainFramework
    } = useLaunchAppchain();


    const [isHovered] = useState(false);
    const [validProjectName, setValidProjectName] = useState(null);

    const validateProjectName = (name) => {
        const isValid = name.length >= 3;
        setValidProjectName(isValid);
        return isValid;
    };

    const handleProjectNameChange = (e) => {
        const name = e.target.value;
        setProjectName(name);
        validateProjectName(name);
    };

    const handleServiceToggle = (service) => {
        setSelectedServices(prev => {
            const newServices = { ...prev, [service]: !prev[service] };
            return newServices;
        });
    };

    return (
        <div className="container mx-auto px-4 py-8 relative">
            <div className="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div className="lg:col-span-2 relative">

                    <h2 className="text-2xl text-white mb-6 pl-4">Build Your Appchain</h2>
                    <div className="bg-[#0A0A22] rounded-lg p-6 border border-white/15 pl-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                            <div>
                                <label htmlFor="projectName" className="block text-normal font-thin text-white mb-2">
                                    Name your project
                                </label>
                                <div className="relative">
                                    <input
                                        id="projectName"
                                        placeholder="Project Name"
                                        onChange={handleProjectNameChange}
                                        className={`bg-[#0F0F2D] border ${validProjectName === true || validProjectName === null ? 'border-white/10' : 'border-red-500'} text-white w-full p-3 pr-10 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500`}
                                        defaultValue={projectName}
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <FaEdit className="text-white" />
                                    </span>
                                </div>
                                {validProjectName === false && (
                                    <p className="text-red-500 text-sm mt-1">Project name is too short.</p>
                                )}
                            </div>
                            <div>
                                <label htmlFor="visibility" className="block text-normal font-thin text-white mb-2">
                                    Choose the visibility of your project
                                </label>
                                <select
                                    id="visibility"
                                    onChange={(event) => setProjectVisibility(event.target.value)}
                                    className="w-full p-3 bg-[#0F0F2D] text-white border border-white/10 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                                >
                                    {projectVisibilities.map(visibility => (
                                        <option key={visibility.value} value={visibility.value}>
                                            {visibility.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
                            <Button
                                text="Madara"
                                logo={MadaraLogo}
                                onClick={() => changeAppchainFramework("madara")}
                                className={`text-sm w-full transition-transform transform ${selectedFramework === "madara" ? "border-white/80" : "border-white/10"}`}
                            >
                                <Tooltip icon={Info} text="Powered by the Madara Alliance" />
                            </Button>
                            <Button
                                text="Katana"
                                logo={KatanaLogo}
                                onClick={() => changeAppchainFramework("katana")}
                                className={`text-sm w-full transition-transform transform ${selectedFramework === "katana" ? "border-white/80" : "border-white/10"}`}
                            >
                                <Tooltip icon={Info} text="Powered by Cartridge" />
                            </Button>
                            <FrameworkVersion
                                selectedFramework={selectedFramework}
                                selectedVersion={selectedVersion}
                                setSelectedVersion={setSelectedVersion}
                                katanaImages={katanaImages}
                                className="w-full bg-[#0A0A22] text-white border border-gray-700 rounded-md"
                            />
                        </div>

                        {error?.metadata && (
                            <div className="bg-red-500 text-white p-4 rounded-md mb-4">
                                <p>{error.metadata}</p>
                            </div>
                        )}

                        <div className="mb-6">
                            {selectedFramework === "madara" ? (
                                <MadaraSpecs metaData={metaData} setMetaData={setMetaData} />
                            ) : (
                                <KatanaSpecs metaData={metaData} setMetaData={setMetaData} />
                            )}
                        </div>

                        <div>
                            <h5 className="text-white text-normal font-thin mb-2">Deploy additional services</h5>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <AdditionalService
                                    logo={quasmLogo}
                                    name="Custom Block Explorer"
                                    className="bg-[#0F0F2D]"
                                    tooltip="Block Explorer service is used to track all transactions on your appchain, customized with your brand and logo"
                                    checked={selectedServices.blockExplorer}
                                    onChange={() => handleServiceToggle('blockExplorer')}
                                />
                                <AdditionalService
                                    logo={ToriiLogo}
                                    name="Torii Indexer"
                                    tooltip="Torii service is used to index data on your Dojo world providing graphql endpoints"
                                    checked={selectedServices.torii}
                                    onChange={() => handleServiceToggle('torii')}
                                />
                            </div>
                            <p className="mt-4 text-sm text-gray-400">
                                🚀 More services coming soon...
                            </p>
                        </div>
                    </div>
                </div>

                <div className="lg:col-span-1">
                    <h2 className="text-2xl text-white mb-6">Summary</h2>
                    <div className="bg-[#0A0A22] border border-gray-700 rounded-lg p-6">
                        <h4 className="text-white mb-4 text-xl">Your Appchain</h4>
                        <AnimatePresence mode="popLayout">
                            {summaryItems.map((summary, idx) => (
                                <motion.div
                                    key={summary}
                                    initial={{ opacity: 0, filter: 'blur(4px)' }}
                                    animate={{ opacity: 1, filter: 'blur(0px)' }}
                                    exit={{ opacity: 0, filter: 'blur(4px)' }}
                                    transition={{ duration: 0.3 }}
                                    layout
                                    className="flex items-center mb-3 pb-3 border-b border-white/15"
                                >
                                    <FaCheckCircle className="size-4 text-white mr-3" />
                                    <p className="text-white/80 font-thin">{summary}</p>
                                </motion.div>
                            ))}
                        </AnimatePresence>

                        <h5 className="text-xl mt-6 mb-4 text-white">Costs and pricing</h5>
                        {[{ label: 'Deployment Cost', value: 'Free' }, { label: 'Plan Cost', value: 'Free' }, { label: 'Your Savings', value: '34 hours' }].map((item, idx) => (
                            <div key={idx} className="flex justify-between mb-3 pb-2 border-b border-gray-600">
                                <p className="text-[#6C6C7A]">{item.label}</p>
                                <p className="text-white">{item.value}</p>
                            </div>
                        ))}

                        <div className="relative mt-6">
                            <button
                                className={`w-full py-2 rounded transition-colors duration-300 ${!validProjectName
                                    ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                                    : 'bg-white text-black hover:bg-gray-800 hover:text-white hover:border hover:border-gray-800'
                                    }`}
                                onClick={handleSubmit}
                                disabled={!validProjectName && !isSubmitting}
                            >
                                Launch Your Appchain
                            </button>
                            {!validProjectName && isHovered && (
                                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-max p-2 text-xs text-black bg-white rounded-lg shadow-lg z-10">
                                    Enter a project name
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {error.permissionDenied && <AlphaSignupModal setError={setError} />}
        </div>
    );
}

const AdditionalService = ({ logo, name, tooltip, checked, onChange }) => (
    <div className="flex justify-between items-center bg-[#0F0F2D] p-4 rounded-lg border border-white/10">
        <div className="flex items-center space-x-3">
            <img src={logo} alt={`${name} Logo`} className="h-6 w-6" />
            <span className="text-white text-sm">{name}</span>
            <Tooltip icon={Info} text={tooltip} />
        </div>
        <Switch
            checked={checked}
            onChange={onChange}
            className={`${checked ? 'bg-blue-500' : 'bg-gray-400'} relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
        >
            <span className={`${checked ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ease-in-out`} />
        </Switch>
    </div>
);

const AlphaSignupModal = ({ setError }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center relative">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Join Our Exclusive Alpha Program!</h2>
            <p className="mb-6 text-gray-600">
                Get early access to enterprise features and help shape the future of our product.
            </p>
            <Link
                to="/alpha-signup"
                className="inline-block bg-blue-600 hover:bg-blue-700 text-white text-lg font-semibold py-3 px-6 rounded-lg shadow-md transition-colors duration-200"
            >
                Join the Alpha Program Now
            </Link>
            <button
                onClick={() => setError(prev => ({ ...prev, permissionDenied: false }))}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
                aria-label="Close"
            >
                ✕
            </button>
        </div>
    </div>
);
