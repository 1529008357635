import React, { useEffect, useState } from 'react';
import { NavLink, Routes, Route, useLocation } from 'react-router-dom';
import Authentication from './Teams/Authentication';
import Invoices from './Teams/Invoices';
import Requests from './Teams/Requests';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HiDotsVertical } from "react-icons/hi";
import BackButton from 'components/ui/BackButton';



const Teams = () => {
    const location = useLocation();
    const [teams, setTeams] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newTeam, setNewTeam] = useState({ title: '', description: '', members: '' });

    // const options = [
    //     { value: 'one', label: 'One' },
    //     { value: 'two', label: 'Two', className: 'myOptionClassName' },
    //     {
    //      type: 'group', name: 'group1', items: [
    //        { value: 'three', label: 'Three', className: 'myOptionClassName' },
    //        { value: 'four', label: 'Four' }
    //      ]
    //     },
    //     {
    //      type: 'group', name: 'group2', items: [
    //        { value: 'five', label: 'Five' },
    //        { value: 'six', label: 'Six' }
    //      ]
    //     }
    //   ];

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTeam({ ...newTeam, [name]: value });
    };

    const validateEmails = (emails) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emails.split(',').every(email => emailPattern.test(email.trim()));
    };

    const handleCreateTeam = () => {
        const { title, description, members } = newTeam;
        if (title && description && members) {
            if (validateEmails(members)) {
                const updatedTeams = [...teams, { ...newTeam, members: members.split(',').map(email => email.trim()) }];
                setTeams(updatedTeams);
                toast.success('Team created successfully!');
                closeModal();
            } else {
                toast.error('Please enter valid email addresses');
            }
        } else {
            toast.error('Please fill in all fields');
        }
    };

    useEffect(() => {
        fetch('/mockData.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => setTeams(data))
            .catch(error => toast.error(`Error fetching data: ${error.message}`));
    }, []);

    const getTitle = () => {
        switch (location.pathname) {
            case '/teams/authentication':
                return 'Authentication';
            case '/teams/invoices':
                return 'Invoices';
            case '/teams/requests':
                return 'Requests';
            default:
                return 'Teams';
        }
    };

    return (
        <div className="space-x-20 justify-center min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-row p-16 overflow-x-hidden">

            {/* Left Side */}
            <div className='flex flex-row'>
                <div className='mr-10'>
                    <BackButton />
                </div>

                <div>
                    <h1 className='text-3xl font-regular text-white mb-6'>Your Account</h1>
                    <div className='flex flex-col space-y-2'>
                        <NavLink to="/teams/authentication" className="text-xl font-regular text-white30 hover:text-white transition hover:-translate-y-1">Authentication</NavLink>
                        <NavLink to="/teams/invoices" className="text-xl font-regular text-white30 hover:text-white transition hover:-translate-y-1">Invoices</NavLink>
                        <NavLink to="/teams/requests" className="text-xl font-regular text-white30 hover:text-white transition hover:-translate-y-1">Requests</NavLink>
                        <NavLink to="/teams" className="text-xl font-regular text-white30 hover:text-white transition hover:-translate-y-1">Teams</NavLink>
                    </div>
                </div>
            </div>

            {/* Right Side */}
            <div className='ml-20 flex flex-col items-center'>

                {/* Subtitle and button */}
                <div className="flex justify-between items-center mb-6 min-w-[800px] max-w-[800px]">
                    <h1 className="text-3xl font-regular">{getTitle()}</h1>
                    <button className="bg-white text-black px-4 py-2 rounded transition hover:-translate-y-1" onClick={openModal}>Create a team</button>
                </div>

                <Routes>
                    <Route path="/" element={<TeamOverview teams={teams} />} />
                    <Route path="authentication" element={<Authentication />} />
                    <Route path="invoices" element={<Invoices />} />
                    <Route path="requests" element={<Requests />} />
                </Routes>

                {/* Create Team Modal */}
                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-slate-200 p-6 rounded-lg shadow-lg text-black">
                            <h2 className="text-2xl mb-4">Create New Team</h2>
                            <label className="block mb-2">
                                Title:
                                <input
                                    type="text"
                                    name="title"
                                    value={newTeam.title}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border border-gray-300 rounded mt-1"
                                />
                            </label>
                            <label className="block mb-2">
                                Description:
                                <textarea
                                    name="description"
                                    value={newTeam.description}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border border-gray-300 rounded mt-1"
                                ></textarea>
                            </label>
                            <label className="block mb-4">
                                Members (comma separated emails):
                                <input
                                    type="text"
                                    name="members"
                                    value={newTeam.members}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border border-gray-300 rounded mt-1"
                                />
                            </label>
                            <div className="flex justify-end">
                                <button onClick={closeModal} className="mr-4 bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                                <button onClick={handleCreateTeam} className="bg-blue-500 text-white px-4 py-2 rounded">Create</button>
                            </div>
                        </div>
                    </div>
                )}
                <ToastContainer />
            </div>
        </div>
    );
};

const TeamOverview = ({ teams }) => (
    <>
        {teams.map((team, index) => (
            <div key={index} className="bg-[#0A0A22] p-6 rounded-lg border border-borderc min-w-[800px] max-w-[800px] mb-4">
                <div className="w-full flex flex-row items-center justify-between">
                    <h2 className="text-3xl text-white70 my-4">{team.title}</h2>
                    <HiDotsVertical size={32} />
                </div>
                <h3 className="text-gray-400">{team.description}</h3>
                <div className="mt-4">
                    {team.members.map((member, idx) => (
                        <div key={idx} className="bg-[#0F0F2D] p-6 rounded mb-4 border border-gray-600 text-xl text-white70">{member}</div>
                    ))}
                </div>
                <div className="flex mt-6">
                    <button className="border-2 border-white bg-white text-black px-4 py-2 rounded">Manage ✎</button>
                    <button className="border-2 border-gray-600 bg-gray-700 text-white px-4 py-2 rounded ml-4">Add +</button>
                    <button className="border-2 border-gray-600 bg-gray-700 text-white px-4 py-2 rounded ml-4">Remove -</button>
                </div>
            </div>
        ))}
    </>
);

export default Teams;
