import { IoChevronBack } from 'react-icons/io5';
import { FaRocket } from 'react-icons/fa';


export default function MarketplaceHome(){
    return(


<>
    {/* bg */}
    <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-col px-8 py-16 lg:p-16">
        <div className="flex flex-row">
            <div>
                <button
                    className="bg-transparent py-2 px-4 text-white flex items-center space-x-2"
                    onClick={() => window.history.back()}
                >
                    <IoChevronBack />
                    <span>Back</span>
                </button>
            </div>
            <div className="sm:flex sm:items-center sm:justify-between sm:space-x-10">
                <div>
                    <h3 className="text-3xl text-white font-thin">Marketplace</h3>
                    <p className="text-white/80 text-base font-thin leading-6 text-white">
                        Buy and sell appchain templates
                    </p>
                </div>
            </div>
            {/* table container */}
        </div>
        <div className="grow max-w-[1400px] ml-24">
            {/* Coming Soon Section */}
            <div className="flex items-center justify-center h-full p-10">
                <div className="bg-white text-black p-8 rounded-lg shadow-md text-center">
                    <div className="flex items-center justify-center mb-4">
                        <FaRocket className="text-5xl text-blue-500 animate-pulse" />
                    </div>
                    <h4 className="text-2xl font-bold mb-2">Exciting New Feature Coming Soon!</h4>
                    <p className="text-lg mb-4">
                        We're working hard to bring you an incredible marketplace experience. Stay tuned!
                    </p>
                    {/* <button className="mt-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white py-2 px-6 rounded-full hover:scale-105 transform transition duration-300">
                        Notify Me
                    </button> */}
                </div>
            </div>
        </div>
    </div>
</>

    )
}