
export class FaucetMixin {

    async dispenseFaucet(projectId, appchainId, toAddress, amount){
        let data = {
            "to_address": toAddress,
            "amount": amount
        }
        let url = this.getUri(projectId) + appchainId + "/dispence-faucet/";
        return this.apiClient.post(url, data)
    }

}