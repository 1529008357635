import React, { useState, useEffect } from 'react';
import SearchBar from '../../components/SearchBar.jsx';
import useProject from "../../hooks/deployments/useproject";
import projectService from "../../services/deployments/project";
import ProjectCard from "../../components/deployments/ProjectCard";
import CreateProjectButton from "../../components/deployments/CreateProjectButton";
import ProjectCardSkeleton from "../../components/deployments/ProjectCardSkeleton";
import CallToAction from "../../components/deployments/CallToAction.tsx";

const ProjectsPage = () => {
  const { projects, fetchProjects, setProjects } = useProject(projectService);
  const [loadingState, setLoadingState] = useState(true);

  useEffect(() => {
    fetchProjects().then(() => setLoadingState(false));
  }, []);

  return (
    <div className="flex bg-gradient-to-b from-[#020215] to-[#12121C] justify-center p-6 min-h-screen pt-20">
      <div className='flex flex-col w-full max-w-7xl'>
        <div className="flex justify-between items-start mb-5">
          <SearchBar onSearch={fetchProjects} />
          <CreateProjectButton />
        </div>
        <div className="max-w-[1500px] mt-2" style={{ 
          opacity: loadingState ? 0.5 : 1,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
        }}>
          {loadingState
            ? Array.from({ length: 6 }).map((_, index) => <ProjectCardSkeleton key={index} />)
            : projects.length > 0
              ? projects.map((project, index) => (<ProjectCard project={project} key={index} projects={projects} setProjects={setProjects} />))
              : <CallToAction/>
          }
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
