import React, { useState } from 'react';
import BackButton from 'components/ui/BackButton';
import { HiDotsVertical } from 'react-icons/hi';
import { MdEdit } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";

const toolsList = [
    { name: 'Torii', key: 'torii' },
    { name: 'Block Explorer', key: 'blockExplorer' },
    { name: 'Saya', key: 'saya' }
];

const CreateAppchain = () => {
    const [selectedTools, setSelectedTools] = useState(toolsList.map(tool => tool.key));
    const [loading, setLoading] = useState(false);

    const toggleToolSelection = (key) => {
        setSelectedTools(prevSelectedTools =>
            prevSelectedTools.includes(key)
                ? prevSelectedTools.filter(toolKey => toolKey !== key)
                : [...prevSelectedTools, key]
        );
    };

    const handleDeploy = () => {
        setLoading(true);
        // trigger something after
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white p-6">
            <div className='flex flex-row fixed'>
                <div className="ml-6 flex items-center">
                    <BackButton />
                </div>

                <div className='ml-20 mt-16'>
                    <h1 className='text-4xl font-regular text-white mb-6'>Nearly Done</h1>
                    <div className='flex flex-col space-y-2'>
                        <div className="text-xl font-regular text-white30 hover:text-white transition hover:-translate-y-1">Select Type and Tools</div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center mt-16'>
                <div className="bg-[#0A0A22] p-6 rounded-lg border border-borderc min-w-[800px] max-w-[800px] mb-4">
                    <div className="w-full flex flex-row items-center justify-between">
                        <h2 className="text-3xl text-white70 my-2">Name it</h2>
                        <HiDotsVertical size={32} />
                    </div>
                    <h3 className="text-gray-400 text-lg">What is going to be the title of your appchain</h3>

                    {/* Naming Bar */}
                    <div className="relative w-full my-4 flex justify-start">
                        <MdEdit className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={28} />
                        <input
                            type="text"
                            placeholder="Appchain Name..."
                            className="w-full pl-14 pr-4 py-4 bg-[#0B0B20] border border-input-border text-white text-xl rounded-lg focus:outline-none focus:ring-2 focus:ring-white hover:bg-[#0E0E28]" />
                    </div>

                    <div className="w-full flex flex-row items-center justify-between">
                        <h2 className="text-3xl text-white70 my-2">Essential Tools</h2>
                        <HiDotsVertical size={32} />
                    </div>
                    <h3 className="text-gray-400 text-lg">Included by default, click to de-select</h3>

                    <div className="mt-4">
                        {toolsList.map(tool => (
                            <div
                                key={tool.key}
                                onClick={() => toggleToolSelection(tool.key)}
                                className={`p-6 border border-gray-600 text-xl cursor-pointer flex items-center transition 
                                    ${selectedTools.includes(tool.key) ? 'bg-[#191941] text-white70 hover:bg-[#15153C]' : 'bg-[#0A0A22] text-gray-400'}`}
                            >
                                <span className="flex-1">{tool.name}</span>
                                {selectedTools.includes(tool.key) && <IoCheckmarkCircle size={24} />}
                            </div>
                        ))}
                    </div>
                    
                    <div className='flex flex-col'>
                        <a href="https://insigh.to/b/quasm-features" target="_blank" rel="noopener noreferrer">
                            <button className='text-xl text-gray-400 mt-4 mb-6'>Request More +</button>
                        </a>
                        <button
                            className="my-4 text-black px-12 py-4 bg-white hover:text-black hover:scale-[1.01] transition duration-200"
                            onClick={handleDeploy}
                        >
                            Deploy Appchain
                        </button>
                    </div>
                    
                </div>


            </div>
            {loading && <p>Loading</p> }
        </div>
    );
};

export default CreateAppchain;
