import React, { useState } from 'react';
import { Badge, Button } from '@tremor/react';
import { IoMdEye, IoMdCopy } from "react-icons/io";
import { FiPause, FiPlay } from "react-icons/fi";
import { FaRegTrashAlt, FaEllipsisV } from "react-icons/fa";
import { MdRestartAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getStatusColor } from "./utils/appchainStatus";
import katanaLogo from '../../imgs/tools/katana.svg';
import madaraLogo from "../../imgs/icons/madara.png";
import torii from '../../imgs/tools/torii.svg';
import quasmLogo from '../../imgs/quasm_trans.png';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import { DeployableTool } from "./DeployableTool";
import openUrlInNewTab from 'utils/openUrl';
import { getAppchainService } from "../../factories/deployments/appchainServiceFactory";
import ConfirmModal from '../ConfirmModal';
import { motion, AnimatePresence } from 'framer-motion';
import {DropdownButton} from '../ui/DropdownButton';


const TOOL_IMG_MAP = {
  'katana': katanaLogo,
  'torii': torii,
  'quasm_compass': quasmLogo
};

const DetailedAppchainCard = ({ appChain, setAppChains, icon, onDelete, onAction, fetchAppChains }) => {
  
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigateTo = (path) => navigate(`/projects/${appChain.project}/${appChain.appchain_type}/${appChain.id}${path}`);


  const handleAction = (action) => {
    getAppchainService(appChain.appchain_type)[action](appChain.project, appChain.id)
      .then(resp => {
        onAction();
      });
  };

  const handleDeleteClick = () => setShowConfirmModal(true);

  const handleConfirmDelete = () => {
    setShowConfirmModal(false);
    getAppchainService(appChain.appchain_type).delete(appChain.project, appChain.id)
      .then(() => onDelete(appChain.id));
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(`https://${appChain.url}`);
    toast.success("URL copied");
  };


  return (
    <div className='bg-[#0B0B20] rounded-lg p-6 border border-white/10 space-y-4'>
      <ToastContainer />
      <div className='flex justify-between items-center'>
        <div className='flex justify-between items-center w-full p-4 '>
          <Badge color={getStatusColor(appChain)} icon={icon}>
            {appChain.is_deployed && !appChain.is_error ? appChain.status :
              appChain.is_error ? 'Error during deployment' : 'Deploying ...'}
          </Badge>
          {!appChain.is_deployed && !appChain.is_error && (
            <div className='flex items-center w-2/3 ml-4'>
              <span className='mr-2 text-gray-500'>Progress:</span>
              <div className='relative w-full h-4 bg-[#1C1C3A] rounded'>
                <div
                  className='absolute h-4 bg-green-500 rounded transition-width duration-300 ease-in-out'
                  style={{ width: `${appChain.deployment_progress}%` }}
                />
              </div>
              <span className='ml-2 text-gray-500'>{appChain.deployment_progress}%</span>
            </div>
          )}

        </div>
        {appChain.is_deployed && (
          <div className='relative'>
            <Button
              className='w-8 h-8 flex items-center justify-center'
              onClick={() => setShowDropdown(!showDropdown)}
              icon={FaEllipsisV}
              variant="secondary"
              aria-label="Options"
            />
            <AnimatePresence>
              {showDropdown && (
                <motion.div
                  className='absolute right-0 mt-2 bg-[#0F0F2D] border border-white/10 rounded-lg shadow-lg p-2 z-50'
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  {
                    appChain.appchain_type !== 'madara'
                      && <DropdownButton onClick={() => navigateTo('')} icon={IoMdEye}>View</DropdownButton>
                  }

                  {!appChain.is_starting && !appChain.is_stopping && (
                    <>
                      <DropdownButton onClick={() => handleAction('restart')} icon={MdRestartAlt}>Restart</DropdownButton>
                      <DropdownButton
                        onClick={() => handleAction(appChain.is_started ? 'stop' : 'start')}
                        icon={appChain.is_started ? FiPause : FiPlay}
                      >
                        {appChain.is_started ? 'Pause' : 'Start'}
                      </DropdownButton>
                    </>
                  )}

                  <DropdownButton onClick={handleDeleteClick} icon={FaRegTrashAlt}>Delete</DropdownButton>
                  <DropdownButton onClick={() => navigateTo('/logs')} icon={IoMdEye}>Logs</DropdownButton>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>

      <div className='space-y-2'>
        <div className='flex flex-col'>
          <div className='flex items-center -mb-4'>
            <img src={appChain.appchain_type == "madara" ? madaraLogo : katanaLogo} style={{ height: "20px", width: "20px" }}></img>
            <h2 className='text-3xl font-thin text-white ml-4'>{appChain.name}</h2>
          </div>
          <div className='flex justify-between items-center'>
            <div className="flex items-center space-x-2">
              <p
                onClick={() => openUrlInNewTab(appChain.url)}
                className="text-sm text-blue-300 hover:text-blue-400 underline cursor-pointer pl-9"
              >
                https://{appChain.url}
              </p>
              <button
                onClick={handleCopyUrl}
                className="p-2 m-4 rounded-md hover:bg-white/10 transition text-blue-300 hover:text-blue-400"
                aria-label="Copy URL"
              >
                <IoMdCopy />
              </button>
            </div>
            <p className="text-sm text-white/50">{appChain.version}</p>
          </div>
        </div>
        <p>{appChain.image ? appChain.image.version : null}</p>
       
      </div>

      <div className='space-y-4'>
        {appChain.tools.map((tool) => (
          <div key={tool.name} className='p-4 bg-[#0F0F2D] border border-white/10 rounded-lg'>
            <DeployableTool appchain={appChain} tool={tool} toolImg={TOOL_IMG_MAP[tool.identifier]} fetchAppChains={fetchAppChains}/>
          </div>
        ))}
      </div>

      {appChain.is_deployed && (
        <div className='flex space-x-4'>

          <ActionButton onClick={() => navigateTo('')} icon={IoMdEye}>Detail</ActionButton>
          
          <ActionButton onClick={() => navigateTo('/logs')} icon={IoMdEye}>Logs</ActionButton>
        </div>
      )}

      {showConfirmModal && (
        <ConfirmModal
          title="Confirm delete"
          message="Are you sure to delete this service ?"
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

const ActionButton = ({ onClick, icon: Icon, children }) => (
  <button
    onClick={onClick}
    className="flex-1 h-10 bg-[#13132E] text-white border border-white/10 text-base rounded hover:bg-[#1a1a40] transition duration-200 ease-in-out flex items-center justify-center"
    aria-label={children}
  >
    {children}
    <Icon className="ml-2" />
  </button>
);


export default DetailedAppchainCard;
