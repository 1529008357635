import React from "react";
import AnimatedContainer from "../AnimatedContainer";
import { shortString } from "starknet";
import { useAccount, useAddChain, useSwitchChain } from "@starknet-react/core";
import { CryptoConnect } from "components/payment/cryptoConnect";

const AddChainComponent = ({ appchain }) => {

    const chainData = {
      id: appchain.metadata.chain_id,
      chain_id: appchain.metadata.chain_id,
      chain_name: appchain.project_name,
      rpc_urls: [`https://${appchain.url}`],
      native_currency: {
        type: "ERC20",
        options: {
          address:
            "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7",
          name: "ETHER",
          symbol: "ETH",
          decimals: 18,
        },
      },
    };

    const { isPending, addChain, error } = useAddChain({ params: chainData });
    const { switchChain } = useSwitchChain({
      params: { chainId: shortString.encodeShortString(appchain.metadata.chain_id) },
    });

  console.log(error)

  return (
    <div className="flex space-x-4 mt-4">
      <button
        onClick={()=> addChain()}
        disabled={isPending}
        className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
          isPending
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-white hover:bg-blue-100 text-black"
        }`}
      >
        {isPending ? "Adding..." : "Add Chain"}
      </button>
      <button
        onClick={() => switchChain()}
        className="px-4 py-2 bg-green-300 hover:bg-green-500 text-black rounded-lg transition-colors duration-200"
      >
        Switch Chain
      </button>
      {error && (
        <span className="text-red-500 text-sm mt-2">{error.message}</span>
      )}
    </div>
  );
};

const AddChain = ({ appchain }) => {
  const { status } = useAccount();

  return (
    <AnimatedContainer delay={0.2}>
      <div className="p-6 bg-[#0A0A22] border border-white/10 rounded-lg h-80">
        <h2 className="text-2xl font-light text-white mb-4">Chain configuration</h2>
        <div className="space-y-4">
          {status === "connected" ? (
            <AddChainComponent appchain={appchain} />
          ) : (
            <CryptoConnect setConnectionType={() => console.log("Connecting")} />
          )}
        </div>
      </div>
    </AnimatedContainer>
  );
};

export default AddChain;
