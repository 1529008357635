import React from 'react';

const ProjectCardSkeleton = () => {
    return (
        <div className="bg-[#0B0B20] min-w-[400px] border border-input-border text-white rounded-lg shadow-md p-4 flex flex-col justify-between space-y-4 w-full hover:border-white30 animate-pulse">
            <div className="px-2 py-4">
                <div className='flex flex-row justify-between items-center'>
                    <div className="bg-gray-700 h-6 w-40 rounded animate-pulse"></div>
                    <span className='mx-3 text-xs font-medium px-2 py-1 rounded-lg bg-gray-500 text-black animate-pulse'>
                        Loading
                    </span>
                </div>
                <p className="bg-gray-700 h-4 w-32 mt-2 rounded animate-pulse"></p>
                <p className="bg-gray-700 h-4 w-48 mt-2 rounded animate-pulse"></p>
            </div>
        </div>
    );
};

export default ProjectCardSkeleton;
