import {BaseService} from "../core/base";
import {applyMixin} from "../../core/reducers/applyMixin";
import {DeployableServiceMixin} from "./deployableMixin";
import { FaucetMixin } from "./faucetMixin";

class ProjectMadaraService extends BaseService{
    baseUri = "/deployments/projects/<projectId>/madara/";

    getUri(projectId){
        return this.baseUri.replace("<projectId>", projectId);
    }

}


export const projectMadaraService = new ProjectMadaraService();

applyMixin(projectMadaraService, DeployableServiceMixin);
applyMixin(projectMadaraService, FaucetMixin);

export default projectMadaraService;
