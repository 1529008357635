import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Badge } from '@tremor/react';
import { RiRecordCircleFill } from 'react-icons/ri';
import { FiMoreVertical } from 'react-icons/fi';
import DeleteArchiveProjectModal from './DeleteArchiveProjectModal';

const ProjectCard = ({ project, projects, setProjects }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [action, setAction] = useState('archive');

  const openModal = () => {
    setIsMenuOpen(false);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="relative bg-[#0B0B20] min-w-[100%] md:min-w-[47%] lg:min-w-[30%] border border-input-border text-white rounded-lg shadow-md p-4 mr-5 mb-5 flex flex-col justify-between space-y-4 hover:border-white30">
      <div className="px-2 py-4">
        <div className="flex flex-row justify-between">
          <Link to={`/projects/${project.id}`} className="flex flex-col">
            <div className="text-xl text-white truncate max-w-64 mb-4">
              {project.name}
            </div>
            <p className="text-white text-base">
              {project.nb_of_appchains} AppChains
            </p>
            <p className="text-white70 text-base">
              Deployed on {project.date_created_display}
            </p>
          </Link>
          <div className="relative flex flex-col justify-between">
            <button onClick={toggleMenu} className="text-white hover:text-gray-500 flex justify-end">
              <FiMoreVertical size={24} />
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 mt-10 w-48 bg-shadow rounded-md z-20 bg-[#0B0B20] border border-white30">
                <ul>
                  {!project.is_archived &&
                    <li>
                      <button className="w-full text-left px-5 py-4 text-sm text-white" onClick={() => {
                        setAction('archive');
                        openModal();
                      }
                      }>Archive</button>
                    </li>
                  }
                  <li>
                    <button className="w-full text-left px-5 py-4 text-sm text-red-600 hover:bg-red-100 rounded-md" onClick={() => {
                      setAction('delete');
                      openModal();
                    }}>Delete</button>
                  </li>
                </ul>
              </div>
            )}
            <Badge color='green-400' icon={RiRecordCircleFill}>live</Badge>
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <DeleteArchiveProjectModal isOpen={modalIsOpen} onRequestClose={closeModal} projectId={project.id} action={action} projects={projects} setProjects={setProjects} />
      )}
    </div>
  );
};

export default ProjectCard;
