import React from 'react';
import CreateProjectButton from './CreateProjectButton';
import { FiEdit3, FiBox, FiSettings, FiLayers, FiZap } from 'react-icons/fi';

const steps = [
  { text: 'Name your project', icon: FiEdit3 },
  { text: 'Choose the framework you want to use', icon: FiLayers },
  { text: 'Pick the specs you want', icon: FiSettings },
  { text: 'Add any additional services', icon: FiBox },
  { text: 'Deploy in minutes', icon: FiZap },
];

const CallToAction: React.FC = () => {
  return (
    <div className="bg-[#0A0A22] p-6 rounded-lg border border-white/10 text-white w-fit mx-auto">
      <h2 className="text-4xl font-light mb-2">Start below</h2>
      <p className="text-white/50 mb-4">Learn how easy it is to deploy an appchain using Quasm.</p>
      <div className="w-full border-t border-white/15 mb-4"/>
      <ul className="space-y-4 mb-6">
        {steps.map(({ text, icon: Icon }, index) => (
          <li 
            key={index} 
            className="cursor-pointer flex items-center text-white/80 tracking-tight transition-transform duration-300 ease-in-out hover:translate-x-[3px]"
          >
            <div className="bg-gray-700 rounded-full p-2 mr-3">
              <Icon className="w-3 h-3 text-white/80" />
            </div>
            {text}
          </li>
        ))}
      </ul>
      <CreateProjectButton buttonText="Deploy Appchain Now"/>
    </div>
  );
};

export default CallToAction;