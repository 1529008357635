import React, { useState, useEffect } from 'react';

const LatencyGauge = () => {
  const [latency, setLatency] = useState(0);

  const measureLatency = async () => {
    try {
      setTimeout(() => null, Math.random() * 1000);
      setLatency(Math.round((Math.random() * 1000)));
    } catch (error) {
      console.error('Error measuring latency:', error);
      setLatency(-1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(measureLatency, 1000);
    measureLatency();
    return () => clearInterval(intervalId);
  }, []);

  const getGaugeColor = (latency) => {
    if (latency < 100) return 'bg-green-500';
    if (latency < 200) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  return (
    <div className="flex flex-col items-center">
      <div className="relative w-64 h-4 bg-gray-300 rounded-full overflow-hidden">
        <div
          className={`absolute top-0 left-0 h-full ${getGaugeColor(latency)}`}
          style={{ width: `${Math.min(latency, 300) / 3}%` }}
        ></div>
      </div>
      <p className="mt-2">
        {latency >= 0 ? `${latency} ms` : 'Error measuring latency'}
      </p>
    </div>
  );
};

export default LatencyGauge;
