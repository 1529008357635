import React, { useState } from 'react';
import projectService from "../../services/deployments/project";
import PrimaryButton from "../PrimaryButton";
import { toast } from "react-toastify";
import { isValidEmail } from "../../core/validators/index";
import { TbUsersPlus } from "react-icons/tb";


const InviteUsers = ({ project }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [emails, setEmails] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleInvite = async () => {
    const emailList = emails.split(',').map(email => email.trim());
    if (emailList.length === 0 || !emailList.every(isValidEmail)) {
      setError('Please enter valid email addresses.');
      return;
    }
    setIsLoading(true);
    try {
      await projectService.inviteUsers(project.id, emailList);
      setIsOpen(false);
      setEmails('');
      setError(null);
      toast.success('People have been invited successfully');
    } catch (err) {
      setError('An error occurred while sending invitations.');
      toast.error('Failed to send invitations.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PrimaryButton onClick={() => setIsOpen(true)} className="flex items-center h-10" disabled={isLoading}>
        <span className="mr-2 text-sm">Invite</span> <TbUsersPlus className='text-sm' />
      </PrimaryButton>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#0B0B20] border border-white/10 text-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Invite Users to collaborate on {project.name}</h2>
            {error && <p className="text-red-500 bg-red-100/10 p-2 rounded mb-4">{error}</p>}
            <textarea
              className="w-full p-2 bg-[#13132E] border border-white/10 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter emails separated by commas"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              rows={4}
            />
            <div className="mt-4 flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-[#232730] text-white rounded hover:bg-[#2c313c] transition duration-200"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
              <PrimaryButton
                className="px-4 py-2 bg-blue-500 text-dark-tremor-brand-faint rounded hover:bg-blue-600 transition duration-200 disabled:opacity-50"
                onClick={handleInvite}
                disabled={isLoading}
              >
                Send Invitations
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InviteUsers;
