import React, { useState, useEffect } from 'react';
import countryService from 'services/refs/country';
import leadService from 'services/billing/lead';
import BlurFade from 'components/blurFade';
import { motion } from 'framer-motion';
import {
    FaClock, FaSearch, FaCheckCircle, FaTimesCircle, FaDiscord,
    FaTelegram, FaLinkedin, FaPercent
} from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

import { useNavigate } from 'react-router-dom';


const StatusDisplay = ({ status }) => {

    const [totalSignups, setTotalSignups] = useState(0)

    useEffect(() => {
      async function getData(){
        try{
            let res = await leadService.getAlphaLeadCount()
            setTotalSignups(res.detail)
        } catch (e){
            console.log(e)
        }
      }
    
      getData()

      return () => {
        setTotalSignups(0)
      }
    }, [])
    

    const statusProperties = {
        PENDING: {
            color: 'text-yellow-500',
            icon: <FaClock />,
            title: 'Review in Progress',
            description: "Thank you for signing up! We're reviewing your application.",
        },
        UNDER_REVIEW: {
            color: 'text-blue-500',
            icon: <FaSearch />,
            title: 'Application Under Review',
            description: "Your application is under detailed review. We'll get back to you shortly.",
        },
        APPROVED: {
            color: 'text-green-500',
            icon: <FaCheckCircle />,
            title: 'Application Approved',
            description: 'Congratulations! Your application has been approved.',
        },
        REJECTED: {
            color: 'text-red-500',
            icon: <FaTimesCircle />,
            title: 'Application Rejected',
            description: 'Unfortunately, your application was not approved. Feel free to contact us for more details.',
        }
    };

    const { color, icon, title, description } = statusProperties[status] || statusProperties.PENDING;

    return (
        <div className="min-h-screen flex flex-col items-center justify-start mt-14 py-6">
            {/* Total Signups Display */}
            <div className="bg-white shadow-md rounded-full px-6 py-2 mb-6 flex items-center gap-2">
                <span className="text-lg font-semibold text-gray-700">Total Signups:</span>
                <span className="text-lg font-bold text-indigo-600">{totalSignups}</span>
            </div>
            
            {/* Status Card */}
            <div className="text-center p-8 rounded-lg shadow-md bg-white max-w-md w-full">
                <div className={`text-5xl mb-4 ${color}`}>
                    {icon}
                </div>
                <h1 className={`text-3xl font-bold ${color} mb-2`}>
                    {title}
                </h1>
                <p className="text-xl text-gray-700 mb-4">
                    {description}
                </p>
                <p className="text-sm text-gray-500">
                    You will hear from us soon.
                </p>
                <div className="mt-6">
                    <a 
                        href="https://discord.gg/5BSWHGnpFA" 
                        className="flex items-center justify-center gap-2 text-white bg-indigo-500 hover:bg-indigo-600 px-4 py-2 rounded-full shadow-md transition duration-300 ease-in-out"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <FaDiscord className="text-xl" />
                        Join our Discord Server
                    </a>
                </div>
            </div>
        </div>
    );
};


const ApplicationForm = ({ formData, handleChange, handleSubmit, countries }) => {
    const allRequiredFieldsFilled = formData.promotion_code || (formData.project_name && formData.description && formData.country);

    return (
        <div className="min-h-screen flex flex-col lg:flex-row items-center justify-center bg-[#020215] py-10 px-4">
            {/* Form Section */}
            <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className={`bg-[#0A0A22] border ${allRequiredFieldsFilled ? 'border-green-500' : 'border-white/15'} shadow-md rounded-lg p-6 max-w-lg w-full text-white space-y-6`}
            >
                <h2 className="text-2xl font-bold">Join the Alpha Program</h2>
                <form onSubmit={handleSubmit} className="space-y-3">
                    {/* Promo Code */}
                    <div className="relative group">
                        <FaPercent className="absolute left-3 top-2.5 text-white/60 group-hover:text-yellow-500 transition duration-200" />
                        <input
                            type="text"
                            id="promotion_code"
                            placeholder="Promo Code (if any)"
                            className="pl-10 pr-3 py-2 bg-[#0F0F2D] w-full rounded-lg focus:ring-yellow-500 focus:border-yellow-500 text-sm text-white"
                            value={formData.promotion_code}
                            onChange={handleChange}
                        />
                    </div>
                    
                    {/* OR Separator */}
                    <div className="relative flex items-center py-1">
                        <div className="flex-grow border-t border-gray-500"></div>
                        <span className="flex-shrink mx-4 text-gray-300 font-extrabold">OR</span>
                        <div className="flex-grow border-t border-gray-500"></div>
                    </div>

                    {/* Project Name */}
                    <div>
                        <label htmlFor="project_name" className="block text-sm text-white/80">Project Name <span className="text-red-500">*</span></label>
                        <input
                            type="text"
                            id="project_name"
                            className="bg-[#0F0F2D] mt-1 p-2 w-full rounded-lg focus:ring focus:ring-blue-500 text-sm"
                            placeholder="Your Project Name"
                            value={formData.project_name}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Project Description */}
                    <div>
                        <label htmlFor="description" className="block text-sm text-white/80">Description <span className="text-red-500">*</span></label>
                        <textarea
                            id="description"
                            className="bg-[#0F0F2D] mt-1 p-2 w-full rounded-lg focus:ring focus:ring-blue-500"
                            placeholder="Brief description of your project"
                            rows="3"
                            value={formData.description}
                            onChange={handleChange}
                        ></textarea>
                    </div>

                    {/* Country Selection */}
                    <div>
                        <label htmlFor="country" className="block text-sm text-white/80">Country <span className="text-red-500">*</span></label>
                        <select
                            id="country"
                            className="mt-1 p-2 w-full bg-[#0F0F2D] rounded-lg focus:ring focus:ring-blue-500"
                            value={formData.country}
                            onChange={handleChange}
                        >
                            <option value="">Select your country</option>
                            {countries.map((country, index) => (
                                <option key={index} value={country.name.common}>{country.name.common}</option>
                            ))}
                        </select>
                    </div>

                    {/* Social Media Fields */}
                    <div className="space-y-2">
                        <label className="text-sm text-white/80">Social Media (Optional)</label>
                        {[
                            { id: 'telegram', icon: FaTelegram, placeholder: 'Telegram handle' },
                            { id: 'discord', icon: FaDiscord, placeholder: 'Discord username' },
                            { id: 'twitter', icon: FaXTwitter, placeholder: 'X handle' },
                            { id: 'linkedin', icon: FaLinkedin, placeholder: 'LinkedIn profile' }
                        ].map(({ id, icon: Icon, placeholder }) => (
                            <div key={id} className="flex items-center space-x-3 bg-[#0F0F2D] p-1 rounded-lg">
                                <Icon className="text-white/60" />
                                <input
                                    id={id}
                                    className="bg-transparent w-full border-none rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                    placeholder={placeholder}
                                    value={formData.social_media[id]}
                                    onChange={handleChange}
                                />
                            </div>
                        ))}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className={`w-full h-12 rounded-lg mt-4 transition-transform duration-200 transform ${
                            allRequiredFieldsFilled
                                ? 'bg-white text-black hover:bg-gray-200 hover:scale-105'
                                : 'bg-gray-400 text-gray-700 cursor-not-allowed'
                        }`}
                        disabled={!allRequiredFieldsFilled}
                    >
                        Join the Alpha
                    </button>
                </form>
            </motion.div>

            {/* Benefits Section */}
            <div className="ml-5 lg:ml-20 space-y-4 mt-10 lg:mt-0 text-white">
                <BlurFade delay={0.25} inView>
                    <h2 className="text-xl font-bold tracking-tighter sm:text-4xl lg:text-5xl xl:text-6xl/none">
                        Alpha Features 😎
                    </h2>
                </BlurFade>
                <BlurFade delay={0.25 * 2} inView>
                    <span className="text-l text-pretty tracking-tighter sm:text-2xl lg:text-3xl">
                        - Launch 3 Projects
                    </span>
                </BlurFade>
                <BlurFade delay={0.25 * 3} inView>
                    <span className="text-l text-pretty tracking-tighter sm:text-2xl lg:text-3xl">
                        - Access to Torii and Customized Block Explorer
                    </span>
                </BlurFade>
                <BlurFade delay={0.25 * 4} inView>
                    <span className="text-l text-pretty tracking-tighter sm:text-2xl lg:text-3xl">
                        - 24/7 Support
                    </span>
                </BlurFade>
                <BlurFade delay={0.25 * 5} inView>
                    <span className="text-l text-pretty tracking-tighter sm:text-2xl lg:text-3xl">
                        - Scalability: Robust infrastructure supports large-scale business applications
                    </span>
                </BlurFade>
                <BlurFade delay={0.25 * 6} inView>
                    <span className="text-l text-pretty tracking-tighter sm:text-2xl lg:text-3xl">
                        - Security: Deploy on-chain applications that ensure data integrity and transparency
                    </span>
                </BlurFade>
                <BlurFade delay={0.25 * 7} inView>
                    <span className="text-l text-pretty tracking-tighter sm:text-2xl lg:text-3xl">
                        - On-Chain Business App: Set up appchains with ease, tailored for business needs
                    </span>
                </BlurFade>
            </div>
        </div>
    );
};


function BetaSignUpPage() {


    const [betaSignUp, setBetaSignUp] = useState([])
    const [countries, setCountries] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        promotion_code: '',
        project_name: '',
        description: '',
        country: '',
        social_media: {
            telegram: '',
            discord: '',
            twitter: '',
            linkedin: ''
        },
        type: 1 // 1 for alpha type
    });
    const navigate = useNavigate();


    async function getCountries() {
        try {
            let countriesRes = await countryService.list()
            setCountries(countriesRes)
        } catch (e) {
            console.log(e)
        }

    }

    async function getBetaSignUp() {
        try {
            setIsLoading(true);
            let betaSignUpRes = await leadService.list()
            setBetaSignUp(betaSignUpRes);
            setIsLoading(false);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {

        getCountries()
        getBetaSignUp()

        return () => {
            setCountries([])
            setBetaSignUp([])
        }
    }, [])


    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id in formData.social_media) {
            setFormData((prevData) => ({
                ...prevData,
                social_media: {
                    ...prevData.social_media,
                    [id]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [id]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const leadRes = await leadService.createLead(formData);
            if (leadRes?.is_allowed || leadRes?.status){
                navigate("/");
                return;
            }
            setBetaSignUp([leadRes])
        } catch (e) {
            console.log(e)
        }
    };

    if (isLoading){
        return null;
    }
    return (
        <main className='mt-3'>
            {
                betaSignUp.length > 0 ?
                    <StatusDisplay status={betaSignUp[0].status_display}/>
                    :
                    <ApplicationForm
                        formData={formData} 
                        handleChange={handleChange} 
                        handleSubmit={handleSubmit} 
                        countries={countries}
                    />
            }

        </main>
    );
}

export default BetaSignUpPage;
