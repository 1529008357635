import React from "react";
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '@tremor/react';
import { IoChevronBack } from 'react-icons/io5';

export default function MonitorPageSkeleton() {
    return (
        <>
            {/* bg */}
            <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-col p-16">
                <div className="flex flex-row">
                    <div>
                        <button className="bg-transparent py-2 px-4 text-white flex items-start space-x-2 items-center">
                            <IoChevronBack />
                            <span>Back</span>
                        </button>
                    </div>
                    {/* table container */}
                    <div className="ml-10 grow max-w-[1400px]">
                        <div className="sm:flex sm:items-center sm:justify-between sm:space-x-10">
                            <div>
                                <h3 className="text-3xl text-white">
                                    Monitor Services
                                </h3>
                                <p className="mt-1 text-tremor-default leading-6 text-white">
                                    Overview of all the services made by or shared with you.
                                </p>
                            </div>
                        </div>
                        <Table className="mt-8">
                            <TableHead>
                                <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
                                    <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong" />
                                    <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        Project
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        Name
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        Capacity
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        Plan
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-right text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        Date Deployed
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-tremor-content-strong text-right dark:text-dark-tremor-content-strong">
                                        Status
                                    </TableHeaderCell>
                                    <TableHeaderCell className="text-tremor-content-strong text-right dark:text-dark-tremor-content-strong">
                                        Actions
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[1, 2, 3].map((index) => (
                                    <TableRow
                                        key={index}
                                        className="even:bg-tremor-background-muted even:dark:bg-dark-tremor-background-muted"
                                    >
                                        <TableCell><div className="h-6 bg-gray-700 rounded w-6 animate-pulse"></div></TableCell>
                                        <TableCell className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                            <div className="h-4 bg-gray-700 rounded w-24 animate-pulse"></div>
                                        </TableCell>
                                        <TableCell><div className="h-4 bg-gray-700 rounded w-32 animate-pulse"></div></TableCell>
                                        <TableCell><div className="h-4 bg-gray-700 rounded w-16 animate-pulse"></div></TableCell>
                                        <TableCell><div className="h-4 bg-gray-700 rounded w-16 animate-pulse"></div></TableCell>
                                        <TableCell className="text-right"><div className="h-4 bg-gray-700 rounded w-20 animate-pulse"></div></TableCell>
                                        <TableCell className="text-right"><div className="h-4 bg-gray-700 rounded w-12 animate-pulse"></div></TableCell>
                                        <TableCell className="text-right">
                                            <div className="flex flex-row justify-end space-x-2 animate-pulse">
                                                <div className="h-6 bg-gray-700 rounded w-6"></div>
                                                <div className="h-6 bg-gray-700 rounded w-6"></div>
                                                <div className="h-6 bg-gray-700 rounded w-6"></div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>

                <div className="flex flex-col mt-10">
                    <div className="h-8 bg-gray-700 rounded w-24 mb-2 animate-pulse"></div>
                    <div className="flex flex-row space-x-4">
                        <div className="h-6 bg-gray-700 rounded w-32 animate-pulse"></div>
                        <div className="h-6 bg-gray-700 rounded w-32 animate-pulse"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
