import React from "react"


const FrameworkVersion = ({selectedFramework, katanaImages, selectedVersion, setSelectedVersion})=>{

    if (selectedFramework === "katana"){
        return (
            <select
                id="version"
                value={selectedVersion}
                onChange={(event) => setSelectedVersion(event.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 bg-[#0A0A22] text-white border border-gray-700 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
                {
                    katanaImages.length > 0 ?
                    katanaImages.map((image) =>{
                        return <option 
                                    key={image.id} 
                                    value={image.version}>{image.version}</option>
                    })
                    :
                    null
                }
            </select>
        )
    } else {
        return null;
    }

}

export default FrameworkVersion