import 'react-toastify/dist/ReactToastify.css';
import { HiDotsVertical } from "react-icons/hi";
import BackButton from 'components/ui/BackButton';
import { RiCloudFill } from '@remixicon/react';
import { Badge } from '@tremor/react';
import { SearchSelect, SearchSelectItem } from '@tremor/react';
import { Divider } from '@tremor/react';
  

const Support = () => {

    return (
        // main container
        <div className="space-x-20 justify-center min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white flex flex-row p-16 overflow-x-hidden">
            
            {/* Left Side */}
            <div className='space-x-10 flex flex-row'>
                <div className="flex items-start">
                    <BackButton />
                </div>
                <div >
                    <h1 className='text-3xl font-regular text-white'>Appchain Health</h1>
                </div>
            </div>

            {/* Right Side */}
            <div className='flex flex-col justify-start'>
                {/* Project Name and Filter By */}
                    
                    <div className='flex flex-row justify-between'>
                        <h1 className='text-3xl text-white70'>
                            Supply Chain Management Project
                        </h1>
                            <div>

                            {/* <div className="text-center text-sm text-white30">
                            Filter By
                            </div> */}

                            <SearchSelect placeholder = "Filter By...">
                                <SearchSelectItem value="1">Option 1</SearchSelectItem>
                                <SearchSelectItem value="2">Option 2</SearchSelectItem>
                                <SearchSelectItem value="3">Option 3</SearchSelectItem>
                            </SearchSelect>
                        </div>
                    </div>
                    <Divider>Related Appchains Below</Divider>

                {/* Card */}
                    <div className=" bg-[#0A0A22] rounded-lg border border-borderc min-w-[800px] max-w-[800px] mb-4 p-4">
                        <div className="w-full flex flex-row items-center justify-between">
                            <div className='flex flex-col w-full'>
                                <Badge className="mb-4" color="green" icon={RiCloudFill}>operational</Badge>
                                <div className='flex flex-row justify-between items-center w-full'>
                                    <h2 className="text-3xl text-white70 my-2">Payment and Settlement</h2>
                                    <HiDotsVertical size={32} />
                                </div>
                            </div>
                        </div>

                    <h3 className="text-gray-400 text-lg">Working fine</h3>

                        <div className="my-4">
                                <div className='p-6 border border-gray-600 text-xl cursor-pointer flex items-center transition'>
                                    <span className="flex-1">Saya</span>
                                </div>
                                <div className='p-6 border border-gray-600 text-xl cursor-pointer flex items-center transition'>
                                    <span className="flex-1">Torii</span>
                                </div>
                                
                        </div>
                        
                        <div className='flex flex-col'>
                            <button
                                className="mt-8 text-white px-12 py-4 bg-transparent border border-white70 hover:bg-white hover:text-black hover:scale-[1.01] transition duration-200"
                            >
                                Stop Appchain
                            </button>
                        </div>

                    </div>

            </div>

             
        </div>
    );
};
export default Support;