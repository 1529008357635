import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import paymentService from "../../services/billing/payment";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {FaCheckCircle} from "react-icons/fa";


const StripeCheckoutForm = ({plan}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = await paymentService.sendStripeIntent(plan);
        const clientSecret = data.client_secret;

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            }
        });

        if (result.error) {
            setError(result.error.message);
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                setIsPaymentSuccess(true);
                toast.success("Payment succeeded", {
                    onClose: () => navigate("/")
                });
            }
        }
        setLoading(false);
    };

    return (
        <div className="max-w-md mx-auto bg-gray-900 text-white p-8 rounded-lg shadow-lg">
            <ToastContainer/>
            <h2 className="text-2xl font-bold text-center mb-6 text-cyan-400">Checkout</h2>

            {isPaymentSuccess ? (
                <div className="max-w-md mx-auto bg-gray-900 text-white p-8 rounded-lg shadow-lg mt-10 text-center">
                    <div className="flex justify-center mb-4">
                        <FaCheckCircle className="text-green-500" size={50} />
                    </div>
                    <h2 className="text-3xl font-bold mb-4 text-cyan-400">Thank You!</h2>
                    <p className="mb-6">Your payment was successful.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold text-gray-200" htmlFor="card-element">
                            Card Details
                        </label>
                        <CardElement
                            id="card-element"
                            className="p-3 bg-gray-800 text-white rounded-md"
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#ffffff',
                                        '::placeholder': {
                                            color: '#a0aec0',
                                        },
                                    },
                                    invalid: {
                                        color: '#ff0000',
                                    },
                                },
                            }}
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-cyan-500 hover:bg-cyan-600 text-white font-bold rounded-lg shadow-md transition duration-300"
                        disabled={!stripe || loading}
                    >
                        {loading ? 'Processing...' : `Pay ${plan === 'developer' ? 50 : 100} $`}
                    </button>
                    {error && <div className="mt-4 text-red-500">{error}</div>}
                </form>
            )}

        </div>
    );
};

export default StripeCheckoutForm;
