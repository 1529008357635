import { Navigate } from "react-router-dom";
import {useStateContext} from "../../contexts/ContextProvider";

const RequireAuth = ({ children }) => {
  const { user, loading } = useStateContext();

    if (loading) {
      return <div>Loading ...</div>;
    }

    if (!user) {
       return <Navigate to="/login"/>;
    }

    return children;
  };

export default RequireAuth