export const getDeploymentStatusClass = (appChain) => {
      switch (appChain.deployment_status.toUpperCase()) {
          case 'DEPLOYING':
            return 'bg-yellow-500 text-black';
          case 'DEPLOYED':
            return 'bg-green-500 text-black';
          default:
            return 'bg-gray-500 text-black';
        }
    }

export const getStatusClass = (appChain) => {
    switch (appChain.status) {
      case 'Stopped':
        return 'bg-yellow-500 text-black';
      case 'Active':
        return 'bg-green-500 text-black';
      case 'Error':
        return 'bg-amber-500 text-black';
      default:
        return 'bg-gray-500 text-black';
    }
}

export const getStatusColor = (appChain) => {
    if (!appChain.is_deployed) return "blue";
    switch (appChain.status) {
      case 'Stopped':
        return 'yellow';
      case 'Active':
        return 'green';
      case 'Error':
        return 'red';
      default:
        return 'bg-gray-500 text-black';
    }
}


export const getStatusTextAndColor = (status) => {
    switch (status) {
        case 'Error':
            return { text: status, color: 'text-red-500' };
        case 'Active':
            return { text: status, color: 'text-green-500' };
        case 'Stopped':
            return { text: status, color: 'text-yellow-500' };
        default:
            return { text: 'Unknown', color: 'text-gray-500' };
    }
};