import {BaseService} from "../core/base";


class LeadService extends BaseService{
    
    async createLead(data){
        const resp = await this.apiClient.post(
            "/billing/leads/", data
        )
        return resp.data;
    }

    async list(){
        const resp = await this.apiClient.get(
            "/billing/leads/"
        )
        return resp.data;
    }

    async getAlphaLeadCount(){
        const resp = await this.apiClient.get(
            "/billing/leads/count/?type=alpha"
        )
        return resp.data;
    }
}

const leadService = new LeadService();
export default leadService;
