import React, { useState } from 'react';
import projectService from "../../services/deployments/project";

const DeleteArchiveProject = ({ onRequestClose, projectId, action, projects, setProjects }) => {
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      action === "delete" 
        ? projectService.delete(projectId)
        : projectService.archive(projectId);

      setProjects(projects.filter(project => project.id !== projectId))
      onRequestClose();
    } catch (err) {
      console.log(err)
      setError(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="appChain" className="block text-gray-100 text-sm font-normal mb-2 text-center">
        Are you sure you want to {action === "archive" ? "Archive" : "Delete" } this project?
      </label>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={onRequestClose}
          className="bg-gray-500 hover:bg-gray-700 text-white font-normal py-2 px-4 mx-3 rounded focus:outline-none focus:shadow-outline"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-normal py-2 px-4 mx-3 rounded focus:outline-none focus:shadow-outline"
        >
          {action === "archive" ? 'Archive' : 'Delete'}
        </button>
      </div>
    </form>
  );
};

export default DeleteArchiveProject;
