import {BaseService} from "../core/base";

class JobService extends BaseService{
    async getTaskResult(taskId){
        return (await this.apiClient.get(
            `/dispatcher/tasks/${taskId}/`
        )).data
    }

    async pollTaskResult(taskId, interval = 2000, timeout = 30000) {
        const start = Date.now();
        return new Promise((resolve, reject) => {
            const poll = async () => {
                try {
                    const result = await this.getTaskResult(taskId);
                    if (result.status === 'SUCCESS') {
                        resolve(result);
                    } else if (result.status === 'FAILURE') {
                        reject(new Error('Task failed'));
                    } else {
                        if (Date.now() - start >= timeout) {
                            reject(new Error('Polling timed out'));
                        } else {
                            setTimeout(poll, interval);
                        }
                    }
                } catch (error) {
                    reject(error);
                }
            };

            poll();
        });
    }
}

export const jobService = new JobService();
