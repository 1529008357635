import argetXLogo from "../../imgs/argent.svg"
import braavos from "../../imgs/braavos.svg"
import usdc from "../../imgs/usdc.svg"

import { ClipLoader } from 'react-spinners';
import { useConnect, useBalance } from "@starknet-react/core";
import PrimaryButton from "components/PrimaryButton";


export const CryptoConnect = ({setConnectionType})=>{


    const { connect, connectors } = useConnect();

    const makeConnection = (connector)=>{
        connect({ connector })
        setConnectionType(connector.name)
    }

    return (
        <ul>
            {connectors.map((connector) => (
                <li key={connector.id} className="mb-4">
                    <div className="flex items-center">
                        <img src={connector.icon} alt={`${connector.name} icon`} className="mr-4 w-10 h-10" />
                        <PrimaryButton
                            onClick={() => makeConnection(connector)}
                        >
                            {connector.name}
                        </PrimaryButton>
                    </div>
                </li>
            ))}
        </ul>
    )
                            
}


export const Connected = ({joinProgram, connectionType, account, contract, transactionLoading}) => {

    const { data, isLoading } = useBalance({
        address: account,
        token: contract.address, // <- defaults to the ETH token
        watch: true
      });

    return (
        <div className="flex items-center p-4 bg-gray-100 rounded-lg shadow-md">
            <img 
                src={connectionType === "argentX" ? argetXLogo : braavos} 
                alt="Crypto Logo" 
                className="mr-4 w-10 h-10"
            />
            <div className="flex-1">
                <div className="text-gray-600 text-sm mb-2 flex items-center">
                    Balance: 
                    <span className="ml-2">{data ? data.value.toString() : null}</span>
                    <img src={usdc} alt={`USDC Logo`} className="ml-2 w-5 h-5" />
                    <span className="ml-2">{data ? data.symbol : null}</span>
                </div>
                {
                transactionLoading ?
                <ClipLoader color="#22a6b3" size={24} />
                :
                <button 
                    className={
                    connectionType === "argentX" ?
                    `bg-[#FF875B] text-white py-2 px-4 rounded hover:bg-[#e76b43] transition-colors duration-300 w-full`
                    :
                    `bg-[#fdbe3b] text-white py-2 px-4 rounded hover:bg-[#fdbe3b] transition-colors duration-300 w-full`
                    }
                    onClick={joinProgram}
                >
                    Connected - Make Payment
                </button>
                }

            </div>
        </div>
    )

}