import React from 'react';

const PrimaryButton = ({ children, onClick, className = '', disabled = false }) => {
  return (
    <button
      onClick={onClick}
      className={`bg-white text-black py-2 px-4 w-full rounded-lg shadow-md focus:outline-none hover:bg-gray-200 flex items-center justify-center ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
