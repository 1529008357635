import { useState, useEffect } from 'react';

const useProject = (projectService) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchProjects = async (projectName = null, isArchived = false) => {
        setLoading(true);
        setError(null);
        try {
            const data = await projectService.list(projectName, isArchived);
            setProjects(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return {
        projects,
        loading,
        error,
        fetchProjects,
        setProjects
    };
};

export default useProject;
