import React from 'react';
import { motion } from 'framer-motion';

const AnimatedSkeleton = () => (
  <motion.div
    className="bg-[#0A0A22] p-10 space-y-5 rounded-lg border border-white30 mr-5 mb-5"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3, repeat: Infinity, repeatType: 'reverse' }}
  >
    <div className="w-full h-8 bg-gray-700 rounded"></div>
    <div className="w-3/4 h-6 bg-gray-700 rounded"></div>
    <div className="w-1/2 h-6 bg-gray-700 rounded"></div>
  </motion.div>
);

export default AnimatedSkeleton;