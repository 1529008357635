import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center">
      <button 
        onClick={() => navigate(-1)} 
        className="px-4 py-2 rounded-md border border-neutral-300 bg-neutral-100 text-gray-700 text-sm flex items-center hover:-translate-y-1 transform transition duration-200 hover:shadow-md"
      >
        <IoIosArrowBack className="mr-2" />
        Back
      </button>
    </div>
  );
};

export default BackButton;
