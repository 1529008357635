import { CheckedInput, TitleInput, TextLeftInput, SelectInput } from "components/deployments/LaunchInputs";
import Timer from "../../imgs/icons/Timer.svg"
import Link from "../../imgs/icons/Link.svg"
import Info from "../../imgs/icons/Info.svg"
import { TEN_PERCENT_BORDER } from "../styles/styles";

const MadaraSpecs = ({ metaData, setMetaData }) => {

    const handleInputChange = (key, value) => {
        setMetaData({
            ...metaData,
            [key]: value
        });
    };

    return (
        <div className="mt-14">
            <div className="flex justify-between items-center">
                <div className="flex justify-between items-end w-full gap-4">
                    <TitleInput 
                        Title={"Block Time"} 
                        logo={Timer} 
                        placeholder={"(seconds)"} 
                        style={{border: TEN_PERCENT_BORDER}}
                        name="block_time"
                        value={metaData.block_time}
                        onChange={handleInputChange}
                        className="w-1/2"
                    ></TitleInput>

                    <SelectInput
                        className="w-1/2"
                        label="Select a Network"
                        name="network"
                        options={[
                            { label: "Devnet", value: "DEVNET" },
                            { label: "Testnet", value: "TESTNET" },
                            { label: "Mainnet (coming soon)", value: "MAINNET" }
                        ]}
                        placeholder="Select a Network"
                        value={metaData.network ?? ""}
                        onChange={handleInputChange}
                        disabledOptions={["MAINNET"]}
                        labelTooltip="MAINNET Coming Soon for Production"
                    />
                </div>
            </div>
            <div>
                <div className="flex items-start w-full mt-8 gap-4">
                    <TextLeftInput 
                        text={"Disable Gas Fee"} 
                        className="w-1/2" 
                        style={{border: TEN_PERCENT_BORDER}} 
                        icon={Info}
                        tooltip={"Disable charging fee when executing transactions."}
                        >
                        <CheckedInput
                            placeholder="Enabled"
                            value={metaData.is_gas_fee_disabled}
                            name="is_gas_fee_disabled"
                            onChange={handleInputChange}
                        />
                    </TextLeftInput>
                </div>
            </div>
            <div>
                <div className="flex justify-between items-end w-full mt-8 gap-4">
                    <TitleInput 
                        Title={"Chain ID"} 
                        logo={Link} 
                        placeholder={"MADARA by default"}
                        style={{border: TEN_PERCENT_BORDER}} 
                        name="chain_id"
                        value={metaData.chain_id}
                        onChange={handleInputChange}
                        className="w-1/2" 
                    >
                    </TitleInput>
                    <TitleInput
                        Title={"Chain Name"} 
                        logo={Link} 
                        placeholder={"MADARA by default"}
                        style={{border: TEN_PERCENT_BORDER}} 
                        name="chain_name"
                        value={metaData.chain_name}
                        onChange={handleInputChange}
                        className="w-1/2" 
                     ></TitleInput>
                </div>
            </div>
        </div>
    )
}

export default MadaraSpecs;
