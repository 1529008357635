import {BaseService} from "../core/base";
import {applyMixin} from "../../core/reducers/applyMixin";
import {DeployableServiceMixin} from "./deployableMixin";

export class ToriiService extends BaseService{

    getUri(projectId){
        return "/deployments/torii/";
    }
}


const toriiService = new ToriiService();

applyMixin(toriiService, DeployableServiceMixin)
export default toriiService;